import styled from "styled-components";

import { Devices } from "constants";

const { mobile } = Devices;

export const Wrapper = styled.footer`
	color: var(--white);
	margin-top: 58px;
	& > div {
		align-items: center;
		justify-content: center;
		display: flex;
		&:nth-child(2) {
			margin-top: 5px;
		}
	}
	p {
		margin: 0;
		padding-right: 5px;
	}
	a {
		color: var(--white);
	}
	@media screen and (max-width: ${mobile}) {
		p {
			font-size: 12px;
		}
	}
`;
