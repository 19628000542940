import SVG, { Props } from "utils/svg";

export default function Envelope({ color, width, height }: Props) {
	return (
		<SVG
			viewBox="0 0 1000 1000"
			aria-labelledby="Envelope"
			color={color}
			height={height}
			width={width}
		>
			<g>
				<path d="M87.5,197.5l353,279.6c16,12.7,38.1,18.3,59.5,17.2c21.4,1.1,43.4-4.5,59.5-17.2l353-279.6c28.3-22.3,21.9-40.5-14-40.5H500H101.6C65.7,157,59.3,175.3,87.5,197.5z" />
				<path d="M937.9,271.3L552.2,564.2c-14.4,10.8-33.3,16.1-52.1,15.9c-18.9,0.2-37.7-5.1-52.2-15.9L62.1,271.3C33.4,249.6,10,261.2,10,297.2v480.5c0,35.9,29.4,65.3,65.3,65.3H500h424.7c35.9,0,65.3-29.4,65.3-65.3V297.2C990,261.2,966.6,249.6,937.9,271.3z" />
			</g>
		</SVG>
	);
}
