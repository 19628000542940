/* eslint-disable no-return-assign */
import axios from "axios";

export function GetBrowserId() {
	if (window?.Boxever && window?.Boxever?.getID()) {
		const result = window?.Boxever?.getID();

		return result;
	}
}

export function PushIdentifyEvent({ event, onSuccess }) {
	const browserID = GetBrowserId();

	event.browser_id = browserID;

	const message = JSON.stringify(event);

	const clientKey = process.env.REACT_APP_CLIENT_KEY;

	const boxeverAPIEndpoint = `https://api-ap-southeast-2-production.boxever.com/v1.2/event/create.json?client_key=${clientKey}&message=${message}`;

	axios
		.get(boxeverAPIEndpoint)
		.then(function (response) {
			onSuccess(true);
		})
		.catch(function (error) {
			// handle error
		});
}
