import AusGrid from "statics/ausgrid";

import { Wrapper } from "./index.styles";

type Props = {
	size?: "s" | "m" | "l";
	color?: string;
};

export default function Logo({ size, color }: Props) {
	return (
		<Wrapper size={size} className="logo">
			<AusGrid color={color} />
		</Wrapper>
	);
}
