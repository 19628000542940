import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Base from "components/templates/base";
import Button from "components/elements/button";
import IconCircle from "components/elements/icon-circle";
import CardPrimary from "components/sections/card-primary";
import Carousel from "components/sections/carousel";

import { ButtonWrapper, Wrapper } from "./index.styles";

const content = [
	{
		id: "1",
		text: (
			<>
				<p>
					<strong>You are now the boss</strong> and manage Ausgrid&#39;s budget for
					2024-29. You have some tough decisions to make.
				</p>
				<p>
					As a regulated business Ausgrid needs to submit 5 yearly plans that set out
					the services we offer, the cost of those services and therefore the prices
					we&#39;ll charge.
				</p>
			</>
		),
	},
	{
		id: "2",
		text: (
			<>
				<p>
					<strong>
						For each question move the sliders to how YOU would manage your budget.
					</strong>
				</p>
				<p>
					If you aren&#39;t sure about something, you have an advisor ready to inform
					you on each choice.
				</p>
			</>
		),
	},
	{
		id: "3",
		text: (
			<p>
				<strong>Remember it&#39;s more than a game.</strong> We hope you have fun
				making your decision, but remember the choices you make will guide us as we
				prepare our plans for 2024-2029.
			</p>
		),
	},
];

export default function Intro() {
	const [nextBut, setNextBut] = useState(false);
	const navigate = useNavigate();

	return (
		<Base title="How to play" size="f">
			<IconCircle icon="she-pony" />

			<Wrapper>
				<Carousel next={(val: boolean) => setNextBut(val)}>
					{content.map((item) => (
						<CardPrimary key={item.id} content={item.text} />
					))}
				</Carousel>
			</Wrapper>
			<ButtonWrapper>
				<Button
					disabled={!nextBut}
					variant="primary"
					onClick={() => navigate("/tell-us")}
					size="s"
				>
					Get started
				</Button>
			</ButtonWrapper>
		</Base>
	);
}
