import styled, { css } from "styled-components";

import { Devices } from "constants";

const { tablet, mobile } = Devices;

export const textSize = {
	xxl: 64,
	xl: 32,
	l: 24,
	m: 18,
	s: 16,
	xs: 12,
	default: 14,
};

export const getTextSize = (size) => {
	switch (size) {
		case "xxl":
			return css`
				font-size: ${textSize.xxl}px;
				font-weight: 800;
				line-height: 77px;
				@media screen and (max-width: ${mobile}) {
					font-size: 44px;
					line-height: 44px;
					text-align: right;
				}
			`;
		case "xl":
			return css`
				font-size: ${textSize.xl}px;
				font-weight: 700;
				line-height: 39px;
			`;
		case "l":
			return css`
				font-size: ${textSize.l}px;
				line-height: 33.5px;
				@media screen and (max-width: ${mobile}) {
					font-size: 17px;
					line-height: 24px;
				}
			`;
		case "m":
			return css`
				font-size: ${textSize.m}px;
				line-height: 27px;
				letter-spacing: -0.01em;
				/* @media screen and (max-width: ${mobile}) {
					font-size: 14px;
					font-weight: 600;
					line-height: 17px;
					letter-spacing: 0em;
				} */
			`;
		case "s":
			return css`
				font-size: ${textSize.s}px;
				font-weight: 400;
				line-height: 23.8px;
			`;
		case "xs":
			return css`
				font-size: ${textSize.xs}px;
				line-height: 15px;
			`;
		case "default":
		default:
			return css`
				font-size: ${textSize.default}px;
				line-height: 21px;
			`;
	}
};

export const Paragraph = styled.p`
	line-height: 30px;
	${({ size }) => {
		return getTextSize(size);
	}}
`;
