import styled from "styled-components";
import { Devices } from "constants";

const { tablet, mobile } = Devices;

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 100%;
	> p {
		margin: 0;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 24px auto 50px auto;
	width: 100%;
	max-width: 288px;
	gap: 40px;
	button {
		width: 100%;
	}
	@media screen and (max-width: ${mobile}) {
		margin: 16px auto 50px auto;
	}
`;

export const ContentWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 15px;
	p {
		margin: 0;
	}
	> div {
		flex-grow: 1;
	}
	max-width: ${1020 + 35 * 2}px;
	padding: 0 35px;
	@media screen and (max-width: ${tablet}) {
		max-width: 100%;
	}
	@media screen and (max-width: ${mobile}) {
		padding: 0 16px;
		text-align: center;
	}
`;

export const CardsWrapper = styled.div`
	display: grid;
	grid-template-columns: auto auto auto;
	gap: 24px;

	max-width: ${1020 + 35 * 2}px;
	padding: 0 35px;
	@media screen and (max-width: ${tablet}) {
		max-width: 100%;
		grid-template-columns: auto auto;
	}
	@media screen and (max-width: ${mobile}) {
		padding: 0 16px;
	}
`;

export const ButtonWrapperMobile = styled.div`
	margin-top: 24px;
`;
