import { getColor } from "utils/helper";
import SVG, { Props } from "utils/svg";

export default function SolarSun({ color, width, height }: Props) {
	return (
		<SVG
			viewBox="0 0 48 48"
			aria-labelledby="Solar Sun"
			color={color}
			height={height}
			width={width}
		>
			<path
				d="M38.2149 41.6564H8.91275L16.7267 20.1682H46.0289L38.2149 41.6564Z"
				fill="#69C52F"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.01273 42.2869C8.21837 42.5805 8.55429 42.7553 8.91274 42.7553H38.2149C38.677 42.7553 39.0897 42.4662 39.2476 42.032L47.0615 20.5437C47.184 20.2068 47.1345 19.8314 46.9289 19.5378C46.7232 19.2442 46.3873 19.0694 46.0289 19.0694H16.7267C16.2646 19.0694 15.8519 19.3584 15.694 19.7927L7.88007 41.281C7.75757 41.6178 7.80708 41.9933 8.01273 42.2869ZM10.4815 40.5576L17.4963 21.267H44.4601L37.4453 40.5576H10.4815Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.3046 42.6891C18.875 42.8965 19.5054 42.6023 19.7128 42.0319L27.5268 20.5437C27.7341 19.9733 27.4399 19.3429 26.8696 19.1355C26.2993 18.9281 25.6688 19.2223 25.4614 19.7926L17.6475 41.2809C17.4401 41.8512 17.7343 42.4817 18.3046 42.6891Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.072 42.6891C28.6424 42.8965 29.2728 42.6023 29.4802 42.0319L37.2941 20.5437C37.5015 19.9733 37.2073 19.3429 36.637 19.1355C36.0667 18.9281 35.4362 19.2223 35.2288 19.7926L27.4149 41.2809C27.2075 41.8512 27.5017 42.4817 28.072 42.6891Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.7209 30.9123C11.7209 31.5192 12.2129 32.0112 12.8197 32.0112H42.1219C42.7288 32.0112 43.2207 31.5192 43.2207 30.9123C43.2207 30.3055 42.7288 29.8135 42.1219 29.8135H12.8197C12.2129 29.8135 11.7209 30.3055 11.7209 30.9123Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.0289 46.6622C25.6358 46.6622 26.1278 46.1703 26.1278 45.5634V41.9355C26.1278 41.3287 25.6358 40.8367 25.0289 40.8367C24.4221 40.8367 23.9301 41.3287 23.9301 41.9355V45.5634C23.9301 46.1703 24.4221 46.6622 25.0289 46.6622Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.5813 45.5634C17.5813 46.1703 18.0733 46.6622 18.6801 46.6622H31.3777C31.9846 46.6622 32.4766 46.1703 32.4766 45.5634C32.4766 44.9565 31.9846 44.4646 31.3777 44.4646H18.6801C18.0733 44.4646 17.5813 44.9565 17.5813 45.5634Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.7732 5.63916C15.3801 5.63916 15.872 5.1472 15.872 4.54033V1.61011C15.872 1.00324 15.3801 0.511276 14.7732 0.511276C14.1663 0.511276 13.6744 1.00324 13.6744 1.61011V4.54033C13.6744 5.1472 14.1663 5.63916 14.7732 5.63916Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.95295 8.46411C8.38207 8.03499 8.38207 7.33925 7.95295 6.91013L5.88092 4.83809C5.4518 4.40897 4.75606 4.40897 4.32694 4.83809C3.89782 5.26721 3.89782 5.96296 4.32694 6.39208L6.39897 8.46411C6.82809 8.89323 7.52383 8.89323 7.95295 8.46411Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 15.2845C0 15.8913 0.491964 16.3833 1.09883 16.3833H4.02905C4.63592 16.3833 5.12788 15.8913 5.12788 15.2845C5.12788 14.6776 4.63592 14.1856 4.02905 14.1856H1.09883C0.491964 14.1856 0 14.6776 0 15.2845Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.32694 25.7307C4.75606 26.1598 5.4518 26.1598 5.88092 25.7307L7.95295 23.6587C8.38207 23.2296 8.38207 22.5338 7.95295 22.1047C7.52383 21.6756 6.82809 21.6756 6.39897 22.1047L4.32694 24.1767C3.89782 24.6058 3.89782 25.3016 4.32694 25.7307Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.4185 15.2845C24.4185 15.8913 24.9105 16.3833 25.5173 16.3833H28.4475C29.0544 16.3833 29.5464 15.8913 29.5464 15.2845C29.5464 14.6776 29.0544 14.1856 28.4475 14.1856H25.5173C24.9105 14.1856 24.4185 14.6776 24.4185 15.2845Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.5934 8.46411C22.0226 8.89323 22.7183 8.89323 23.1474 8.46411L25.2195 6.39208C25.6486 5.96296 25.6486 5.26721 25.2195 4.83809C24.7903 4.40897 24.0946 4.40897 23.6655 4.83809L21.5934 6.91013C21.1643 7.33925 21.1643 8.03499 21.5934 8.46411Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.6628 22.1214C13.8538 21.5454 13.5417 20.9236 12.9656 20.7326C10.6808 19.9751 9.03482 17.8206 9.03482 15.2845C9.03482 12.1152 11.604 9.54611 14.7732 9.54611C17.9424 9.54611 20.5115 12.1152 20.5115 15.2845C20.5115 15.7662 20.4524 16.2325 20.3416 16.677C20.1948 17.2659 20.5531 17.8622 21.142 18.009C21.7308 18.1558 22.3272 17.7975 22.474 17.2087C22.6279 16.5915 22.7092 15.9467 22.7092 15.2845C22.7092 10.9015 19.1561 7.34844 14.7732 7.34844C10.3902 7.34844 6.83716 10.9015 6.83716 15.2845C6.83716 18.7958 9.11697 21.7719 12.274 22.8186C12.85 23.0096 13.4718 22.6975 13.6628 22.1214Z"
				fill="#2D64B0"
			/>
		</SVG>
	);
}
