const Questions = [
	{
		id: "1",
		title: "Innovation fund",
		icon: "bulb",
		text:
			"Technology is changing quickly, smarter options keep coming. How much should you invest in innovation?",
		question: "What's your approach to innovation?",
		indicators: ["Slow and steady", "Drive change"],
		adviser: {
			icon: "he-tash",
			text:
				"Innovation can deliver lower bills over time, but would increase bills in the short term. With more innovation funding you could invest in more community based trials or become more efficient. But some money may be wasted – not every project will be successful.",
		},
		levels: [
			{ id: "1", usage: 1500, values: [0.08, 0.66, 1.01, 1.84] },
			{ id: "2", usage: 3900, values: [0.1, 0.82, 1.24, 2.27] },
			{ id: "3", usage: 5000, values: [0.11, 0.89, 1.34, 2.46] },
			{ id: "4", usage: 6000, values: [0.13, 1.03, 1.55, 2.85] },
			{ id: "5", usage: 10000, values: [0.26, 2.11, 3.18, 5.84] },
		],
	},
	{
		id: "2",
		title: "Customer Service",
		icon: "chat",
		text:
			"Customers tell you they want better customer service, but everyone wants different things.",
		question: "What level of service will you deliver?",
		indicators: ["Same for everyone", "Tailored services"],
		adviser: {
			icon: "he-mic",
			text: (
				<>
					<p>
						Some customers want information in different languages, some want Chatbots
						and others want to talk to a real person.{" "}
					</p>
					<p>
						Some business customers or even local councils want to use the website as
						a way to put in orders and cut down on paper.{" "}
					</p>
					<p>
						With nearly two million customers and stakeholders, good customer service
						is different for different people. Should services stay the same and keep
						prices down, or should we invest more?
					</p>
				</>
			),
		},
		levels: [
			{ id: "1", usage: 1500, values: [0.0, 0.12, 0.33, 0.43] },
			{ id: "2", usage: 3900, values: [0.0, 0.14, 0.41, 0.53] },
			{ id: "3", usage: 5000, values: [0.0, 0.16, 0.44, 0.58] },
			{ id: "4", usage: 6000, values: [0.0, 0.18, 0.51, 0.67] },
			{ id: "5", usage: 10000, values: [0.0, 0.37, 1.05, 1.37] },
		],
	},
	{
		id: "3",
		title: "Climate change",
		icon: "globe-meter",
		text:
			"Climate change is expected to cause more extreme weather and that means more power outages.",
		question: "What do you do?",
		indicators: ["Do nothing", "Do all you can"],
		adviser: {
			icon: "she-curly",
			text: (
				<>
					<p>
						Climate scientists can predict the impacts of extreme weather with a
						degree of certainty, but you can’t be 100% sure.
					</p>
					<p>
						If you invest in the wrong place at the wrong time that might be wasted
						money that’ll push prices up unnecessarily.{" "}
					</p>
					<p>
						With no investment you can expect some customers to have more frequent and
						longer outages as we get more extreme weather.
					</p>
				</>
			),
		},
		levels: [
			{ id: "1", usage: 1500, values: [0.0, 1.78, 2.17, 5.7] },
			{ id: "2", usage: 3900, values: [0.0, 2.19, 2.67, 7.02] },
			{ id: "3", usage: 5000, values: [0.0, 2.38, 2.9, 7.62] },
			{ id: "4", usage: 6000, values: [0.0, 2.75, 3.36, 8.82] },
			{ id: "5", usage: 10000, values: [0.0, 5.64, 6.88, 18.06] },
		],
	},
	{
		id: "4",
		title: "Cyber security",
		icon: "security",
		text:
			"The threat of cyber attacks  is increasing. So far you’ve kept attacks at bay, but there are now more than ever before.",
		question: "What level of cyber security do you want?",
		indicators: ["Compliant", "Advanced"],
		adviser: {
			icon: "he-shave",
			text: (
				<>
					<p>
						Your advisor tells you there are two main types of cyber attacks. The
						attackers might be trying to access data or they might be trying to cause
						disruption by causing a power outage.{" "}
					</p>
					<p>
						Do you do enough to comply with the governments’ minimum standards,
						keeping costs as low as possible, or do you want to have advanced cyber
						security?
					</p>
				</>
			),
		},
		levels: [
			{ id: "1", usage: 1500, values: [0.81, 1.92, 2.41] },
			{ id: "2", usage: 3900, values: [1.0, 2.37, 2.97] },
			{ id: "3", usage: 5000, values: [1.08, 2.57, 3.22] },
			{ id: "4", usage: 6000, values: [1.25, 2.97, 3.73] },
			{ id: "5", usage: 10000, values: [2.57, 6.09, 7.64] },
		],
	},
	{
		id: "5",
		title: "Net zero",
		icon: "solar-sun",
		text:
			"Parts of the network are feeling the strain of more solar. Do you invest to allow customers to continue to export solar, or do you stop people adding solar where the network is congested?",
		question: "What do you do?",
		indicators: ["Keep costs low", "Invest"],
		adviser: {
			icon: "he-cap",
			text: (
				<>
					<p>
						Customers tell you they want to add more solar panels, put in electric
						vehicle charging stations and make their own investment in a net zero
						future.
					</p>
					<p>Would you prefer to:</p>
					<p>
						<strong>A:</strong> invest in the network to support customers to use or
						export electricity whenever they want to.{" "}
					</p>
					<p>
						<strong>B:</strong> keep costs low, but some customers won’t be able to
						add solar or charge electric vehicles because of a lack of network
						capacity.
					</p>
				</>
			),
		},
		levels: [
			{ id: "1", usage: 1500, values: [0.0, 0.83, 2.25, 4.15] },
			{ id: "2", usage: 3900, values: [0.0, 1.02, 2.77, 5.11] },
			{ id: "3", usage: 5000, values: [0.0, 1.11, 3.01, 5.55] },
			{ id: "4", usage: 6000, values: [0.0, 1.28, 3.48, 6.42] },
			{ id: "5", usage: 10000, values: [0.0, 2.63, 7.12, 13.14] },
		],
	},
	{
		id: "6",
		title: "Pricing changes",
		icon: "dollar-tag",
		text:
			"You've chosen to keep costs low. You therefore need to encourage people to change when they use or export electricity. You are considering charging customers that export power to the grid.",
		question: "How do you introduce solar export charges?",
		indicators: ["All at once", "Gradually"],
		adviser: {
			icon: "he-beard",
			text: (
				<>
					<p>
						What’s the fairest way to change how you charge or reward people for
						exporting electricity?
					</p>
					<p>
						You can introduce the charges now, and then everyone pays the same – but
						customers that have already invested in solar panels weren’t expecting to
						be charged, so is that fair?{" "}
					</p>
					<p>
						You could slowly introduce the charges, starting with new solar customers
						and gradually rolling out new prices to everyone.
					</p>
				</>
			),
		},
		levels: [
			{ id: "1", usage: 1500, values: [0, 0, 0, 0] },
			{ id: "2", usage: 3900, values: [0, 0, 0, 0] },
			{ id: "3", usage: 5000, values: [0, 0, 0, 0] },
			{ id: "4", usage: 6000, values: [0, 0, 0, 0] },
			{ id: "5", usage: 10000, values: [0, 0, 0, 0] },
		],
	},
];
export default Questions;
