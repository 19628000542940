import ArrowRight from "statics/arrow-right";
import ArrowLeft from "statics/arrow-left";
import Bulb from "statics/bulb";
import Chain from "statics/chain";
import Chat from "statics/chat";
import DollarTag from "statics/dollar-tag";
import Facebook from "statics/facebook";
import GlobeMeter from "statics/globe-meter";
import HeTash from "statics/he-tash";
import HomeL from "statics/home-l";
import HomeM from "statics/home-m";
import HomeS from "statics/home-s";
import Instagram from "statics/instagram";
import Linkedin from "statics/linkedin";
import Security from "statics/security";
import SheCurly from "statics/she-curly";
import ShePony from "statics/she-pony";
import Skyscraper from "statics/skyscraper";
import SME from "statics/sme";
import SolarSun from "statics/solar-sun";
import Warning from "statics/warning";
import HeMic from "statics/he-mic";
import HeCap from "statics/he-cap";
import HeShave from "statics/he-shave";
import HeBeard from "statics/he-beard";
import Envelope from "statics/envelope";

import { IconsType } from ".";

export function getIcon(as?: IconsType, color?: string) {
	switch (as) {
		case "arrow-left":
			return <ArrowLeft color={color} />;
		case "arrow-right":
			return <ArrowRight color={color} />;
		case "bulb":
			return <Bulb color={color} />;
		case "chain":
			return <Chain color={color} />;
		case "chat":
			return <Chat color={color} />;
		case "dollar-tag":
			return <DollarTag color={color} />;
		case "envelope":
			return <Envelope color={color} />;
		case "facebook":
			return <Facebook color={color} />;
		case "globe-meter":
			return <GlobeMeter color={color} />;
		case "he-mic":
			return <HeMic color={color} />;
		case "he-tash":
			return <HeTash color={color} />;
		case "he-cap":
			return <HeCap color={color} />;
		case "he-shave":
			return <HeShave color={color} />;
		case "he-beard":
			return <HeBeard color={color} />;
		case "home-l":
			return <HomeL color={color} />;
		case "home-m":
			return <HomeM color={color} />;
		case "home-s":
			return <HomeS color={color} />;
		case "instagram":
			return <Instagram color={color} />;
		case "linkedin":
			return <Linkedin color={color} />;
		case "security":
			return <Security color={color} />;
		case "she-curly":
			return <SheCurly color={color} />;
		case "she-pony":
			return <ShePony color={color} />;
		case "skyscraper":
			return <Skyscraper color={color} />;
		case "sme":
			return <SME color={color} />;
		case "solar-sun":
			return <SolarSun color={color} />;
		case "warning":
			return <Warning color={color} />;
		default:
			return <ArrowRight color={color} />;
	}
}
