import styled, { css } from "styled-components";

import { textSize } from "../text/index.styles";

// xxl: 48,
// xl: 64,
// l: 24,
// m: 18,
// s: 16,
// xs: 10,
// default: 14,

const getFontSize = (size) => {
	switch (size) {
		case "m":
			return css`
				font-size: ${textSize[size]}px;
				font-weight: 600;
				line-height: 24px;
				padding: 16px 32px;
			`;
		case "s":
			return css`
				font-size: ${textSize[size]}px;
				font-weight: 600;
				line-height: 20px;
				padding: 10px 24px;
			`;
		case "default":
		default:
			return css`
				font-size: ${textSize[size]}px;
				font-weight: 600;
				line-height: 17px;
				padding: 16px 32px;
			`;
	}
};

const getTheme = (type) => {
	switch (type) {
		case "success":
			return css`
				background-color: var(--green);
				border: 1px solid var(--green);
				color: var(--white);
				@media (hover: hover) {
					&:not(:disabled):hover {
						background-color: var(--white);
						color: var(--green);
						svg {
							fill: var(--green);
						}
					}
				}
				&:not(:disabled):active {
					background-color: var(--green);
					color: var(--white);
					box-shadow: inset 0px 56px 0px rgba(0, 0, 0, 0.2),
						inset 0px 4px 4px rgba(0, 0, 0, 0.5);
					svg {
						fill: var(--green);
					}
				}
			`;
		case "error":
			return css`
				background-color: var(--red);
				color: var(--white);
				border: 1px solid var(--red);
				@media (hover: hover) {
					&:not(:disabled):hover {
						background-color: var(--white);
						color: var(--red);
						svg {
							fill: var(--red);
						}
					}
				}
				&:not(:disabled):active {
					background-color: var(--red);
					color: var(--white);
					box-shadow: inset 0px 56px 0px rgba(0, 0, 0, 0.2),
						inset 0px 4px 4px rgba(0, 0, 0, 0.5);
					svg {
						fill: var(--white);
					}
				}
			`;

		default:
			return css`
				background-color: var(--primary);
				border: 1px solid var(--primary);
				color: var(--white);
				@media (hover: hover) {
					&:not(:disabled):hover {
						background-color: transparent;
						color: var(--primary);
						svg {
							fill: var(--primary);
						}
					}
				}
				&:not(:disabled):active {
					background-color: var(--primary);
					color: var(--white);
					box-shadow: inset 0px 56px 0px rgba(0, 0, 0, 0.2),
						inset 0px 4px 4px rgba(0, 0, 0, 0.5);
					svg {
						fill: var(--white);
					}
				}
			`;
	}
};

export const Wrapper = styled.button`
	font-family: var(--primaryFont);
	border: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	width: fit-content;
	gap: 10px;
	white-space: nowrap;
	&:disabled {
		background-color: var(--primaryBg);
		border-color: var(--primaryBg);
		color: var(--grey);
		cursor: not-allowed;
	}
	${({ size }) => {
		return getFontSize(size);
	}}

	${({ variant }) => {
		switch (variant) {
			case "transparent":
				return css`
					color: var(--primary);
					background-color: transparent;
					font-weight: 400;
					padding: 0;
					@media (hover: hover) {
						&:not(:disabled):hover {
							text-decoration: underline;
							svg {
								fill: var(--primary);
							}
						}
					}
					&:not(:disabled):active {
						text-decoration: none;
						svg {
							fill: initial;
						}
					}
				`;
			case "underline":
				return css`
					color: var(--primary);
					background-color: transparent;
					font-weight: 400;
					padding: 0;
					strong {
						text-decoration: underline;
					}
					@media (hover: hover) {
						&:not(:disabled):hover {
							strong {
								text-decoration: none;
							}
							svg {
								fill: var(--primary);
							}
						}
					}
					&:not(:disabled):active {
						strong {
							text-decoration: underline;
						}
						svg {
							fill: var(--primary); // fix on adviser page
						}
					}
				`;
			case "outline":
				return css`
					border-radius: 56px;
					background-color: var(--white);
					border: 1px solid var(--primary);
					color: var(--primary);
					@media (hover: hover) {
						&:not(:disabled):hover {
							background-color: var(--primary);
							color: var(--white);
							svg {
								fill: var(--white);
							}
						}
					}
					&:not(:disabled):active {
						background-color: var(--white);
						color: var(--primary);
						box-shadow: inset 0px 56px 0px rgba(0, 0, 0, 0.2),
							inset 0px 4px 4px rgba(0, 0, 0, 0.5);
						svg {
							fill: var(--primary);
						}
					}
				`;
			case "secondary":
				return css`
					border-radius: 56px;
					background-color: var(--white);
					color: var(--green);
					border: 1px solid;
					border-color: var(--green);
					position: relative;
					padding: 10.5px 14px;
					overflow: hidden;
					.icon {
						position: absolute;
						bottom: -6px;
						left: 4px;
						width: 46px;
						& + span {
							margin-left: 35px;
						}
					}
					@media (hover: hover) {
						&:not(:disabled):hover {
							text-decoration: underline;
						}
					}
					&:not(:disabled):active {
						text-decoration: none;
						box-shadow: inset 0px 56px 0px rgba(0, 0, 0, 0.2),
							inset 0px 4px 4px rgba(0, 0, 0, 0.5);
					}
				`;
			case "primary":
			default:
				return css`
					border-radius: 56px;
					${({ theme }) => {
						return getTheme(theme);
					}}
				`;
		}
	}}
`;
