import styled, { css } from "styled-components";
import ReactSlider from "react-slider";

import { Devices } from "constants";

const { tablet, mobile } = Devices;

const getSize = (size) => {
	switch (size) {
		case "s":
			return css`
				height: 10px;
				.track {
					height: 10px;
				}
				.thumb {
					width: 24px;
					height: 24px;
					top: -7px;
				}
			`;
		default:
			return css`
				height: 24px;
				.track {
					height: 24px;
				}
				.thumb {
					width: 40px;
					height: 40px;
					top: -8px;
				}
				@media screen and (max-width: ${tablet}) {
					height: 16px;
					.track {
						height: 16px;
					}
					.thumb {
						top: -12px;
					}
				}
				@media screen and (max-width: ${mobile}) {
					height: 10px;
					.track {
						height: 10px;
					}
					.thumb {
						width: 24px;
						height: 24px;
						top: -7px;
					}
				}
			`;
	}
};

export const StyledSlider = styled(ReactSlider)`
	${({ size }) => getSize(size)}
	width: 100%;
	.track {
		top: 0;
		background-color: var(--green);
		border-radius: 18px;
	}
	.track-1 {
		background: var(--blue600);
	}

	.thumb {
		background-color: var(--primary);
		color: var(--primary);
		cursor: pointer;
		text-align: center;
		border-radius: 50%;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
			0px 1px 3px 1px rgba(0, 0, 0, 0.15);
		&:focus-visible {
			outline-width: 0;
		}
		&:active {
			cursor: grabbing;
		}
	}
	.mark {
		margin: 0px calc(17px);
		bottom: calc(50% - 10px);
		width: 8px;
		height: 8px;
		background-color: var(--grey);
		cursor: pointer;
		border-radius: 50%;
		vertical-align: middle;
	}
`;
