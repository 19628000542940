import styled, { css } from "styled-components";
import { Devices } from "constants";

const { tablet, mobile } = Devices;

const getSize = (size) => {
	switch (size) {
		case "s":
			return css`
				width: 88px;
				height: 88px;
			`;
		case "m":
		default:
			return css`
				width: 120px;
				height: 120px;
				@media screen and (max-width: ${mobile}) {
					width: 88px;
					height: 88px;
				}
			`;
	}
};

export const Wrapper = styled.div`
	background-image: url(${(props) => props.url});
	background-color: var(--white);
	background-repeat: no-repeat;
	background-position: 25px 12px;
	box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);
	${({ size }) => getSize(size)}
	border-radius: 50%;
	overflow: hidden;
	.icon {
		width: 100%;
	}
`;
