import styled, { css } from "styled-components";

export const Wrapper = styled.div`
	display: flex;

	${({ size }) => {
		switch (size) {
			case "l":
				return css`
					width: 48px;
				`;
			case "m":
				return css`
					width: 19px;
				`;
			case "s":
				return css`
					width: 15px;
				`;
			case "xs":
				return css`
					width: 10px;
				`;
			case "default":
			default:
				return css`
					width: 25px;
				`;
		}
	}}
`;
