import { createSlice } from "@reduxjs/toolkit";

import TotalUsage from "data/total-usage.json";
import InvestorTypeData from "data/investor-type.json";
import { addZeroes } from "utils/helper";

const initialState = {
	currentBill: "0",
	userTotal: "0",
	investorType: {},
};

export const totalsSlice = createSlice({
	name: "totals",
	initialState,
	reducers: {
		updateTotals: (state, action) => {
			const { usage, answers } = action.payload;

			const getTotalUsage = TotalUsage.filter(
				(item) => item.usage === usage.value
			)[0];

			const levelsTotal = answers.reduce((a, b) => a + b.level, 0);

			const currentBill = addZeroes(getTotalUsage.total);

			const userTotal = addZeroes(
				Number((Number(currentBill) + Number(levelsTotal)).toFixed(2))
			);

			const filterInvestorByUsage = InvestorTypeData.filter(
				(item) => item.usage === usage.value
			)[0];

			const findInvestorType = filterInvestorByUsage.figures.filter(
				(item) => userTotal >= item.values[0] && userTotal <= item.values[1]
			);

			const title = findInvestorType[0]?.title;
			const description = findInvestorType[0]?.description;

			return {
				currentBill,
				userTotal,
				investorType: {
					title,
					description,
				},
			};
		},
		resetTotals: () => initialState,
	},
});

export const { updateTotals, resetTotals } = totalsSlice.actions;
