import styled from "styled-components";

import { Devices } from "constants";

const { tablet, mobile } = Devices;

const fontSize = { one: 24, two: 30, three: 20, four: 18, five: 16.5, six: 15 };

export const H1 = styled.h1`
	font-size: ${fontSize.one}px;
	font-weight: 800;
	line-height: 29px;
	@media screen and (max-width: ${mobile}) {
		font-size: 18px;
		line-height: 22px;
	}
`;
export const H2 = styled.h2`
	font-size: ${fontSize.two}px;
	font-weight: 700;
	line-height: 40px;
`; // TODO
export const H3 = styled.h3`
	font-size: ${fontSize.three}px;
	font-weight: 600;
	line-height: 24px;
`;
export const H4 = styled.h4`
	font-size: ${fontSize.four}px;
	font-weight: 600;
	line-height: 27px;
`; // TODO
export const H5 = styled.h5`
	font-size: ${fontSize.five}px;
	font-weight: 500;
	line-height: 24.75px;
`; // TODO
export const H6 = styled.h6`
	font-size: ${fontSize.six}px;
	font-weight: 500;
	line-height: 22.5px;
`; // TODO
