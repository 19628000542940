import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	gap: 25px;
	.item {
		width: 31.43%;
	}
`;
