import SVG, { Props } from "utils/svg";

export default function Security({ color, width, height }: Props) {
	return (
		<SVG
			viewBox="0 0 48 48"
			aria-labelledby="Security"
			color={color}
			height={height}
			width={width}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M34.6302 43.7395H30.273V39.448L32.3608 37.7986V36.1482L30.273 34.1669V32.7336L32.3608 30.3723V28.7229L30.273 27.0725V20.7006C28.1852 18.8779 24.9522 15.481 24.9522 11.5831C24.9522 5.76232 29.6195 1.04388 35.4403 1.04388C41.2611 1.04388 46.0369 5.76232 46.0369 11.5831C46.0369 16.3558 43.8437 20.3832 38.6242 21.6797V39.4365L34.6302 43.7395ZM32.361 8.24681C32.361 9.97655 33.7629 11.3785 35.4927 11.3785C37.2224 11.3785 38.6244 9.97655 38.6244 8.24681C38.6244 6.51706 37.2224 5.1151 35.4927 5.1151C33.7629 5.1151 32.361 6.51706 32.361 8.24681Z"
				fill="#69C52F"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M29.2291 44.7834H35.0856L39.6681 39.8463V22.4702C42.1042 21.7024 43.9235 20.3271 45.1511 18.5221C46.4975 16.5424 47.0809 14.1231 47.0809 11.5831C47.0809 5.17843 41.8302 -7.74407e-06 35.4403 -7.74407e-06C29.0364 -7.74407e-06 23.9083 5.19243 23.9083 11.5831C23.9083 13.8201 24.8332 15.8491 25.9679 17.5091C26.9842 18.9961 28.2105 20.2482 29.2291 21.1694V27.578L31.3169 29.2284V29.977L29.2291 32.3383V34.6154L31.3169 36.5967V37.293L29.2291 38.9423V44.7834ZM31.3169 42.6956V39.9537L33.4047 38.3043V35.6997L31.3169 33.7184V33.1289L33.4047 30.7676V28.2175L31.3169 26.5671V20.2262L30.9595 19.9142C29.95 19.0329 28.6915 17.794 27.6915 16.331C26.6878 14.8625 25.9961 13.2441 25.9961 11.5831C25.9961 6.33223 30.2027 2.0878 35.4403 2.0878C40.692 2.0878 44.9931 6.34623 44.9931 11.5831C44.9931 13.8159 44.4798 15.7966 43.4247 17.3479C42.3804 18.8834 40.7545 20.075 38.3726 20.6666L37.5803 20.8634V39.0267L34.1749 42.6956H31.3169Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M31.3171 8.24682C31.3171 10.5531 33.1864 12.4224 35.4927 12.4224C37.799 12.4224 39.6683 10.5531 39.6683 8.24682C39.6683 5.94054 37.799 4.07121 35.4927 4.07121C33.1864 4.07121 31.3171 5.94054 31.3171 8.24682ZM35.4927 10.3346C34.3395 10.3346 33.4049 9.40003 33.4049 8.24682C33.4049 7.0936 34.3395 6.15901 35.4927 6.15901C36.6459 6.15901 37.5805 7.0936 37.5805 8.24682C37.5805 9.40003 36.6459 10.3346 35.4927 10.3346Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.5923 17.9306C14.9138 18.4031 15.4483 18.6859 16.0197 18.6859H16.7024C17.279 18.6859 17.7463 18.2185 17.7463 17.642V17.6022C17.7463 17.0476 17.2967 16.598 16.7421 16.598V16.598C16.4098 16.598 16.0989 16.4336 15.9119 16.1588L12.8521 11.6623C12.2869 10.8317 11.3473 10.3346 10.3426 10.3346H8.35121C7.77468 10.3346 7.30731 10.802 7.30731 11.3785V11.3785C7.30731 11.9551 7.77468 12.4224 8.35121 12.4224H9.62025C10.3858 12.4224 11.1017 12.8012 11.5324 13.4341L14.5923 17.9306Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5268 22.8614C12.5268 22.2849 12.0595 21.8175 11.4829 21.8175H8.35121C7.77468 21.8175 7.30731 22.2849 7.30731 22.8614V22.8614C7.30731 23.438 7.77468 23.9053 8.35121 23.9053H11.4829C12.0595 23.9053 12.5268 23.438 12.5268 22.8614V22.8614Z"
				fill="#2D64B0"
			/>
			<path
				d="M7.3073 11.3785C7.3073 13.1082 5.90534 14.5102 4.17559 14.5102C2.44584 14.5102 1.04388 13.1082 1.04388 11.3785C1.04388 9.64874 2.44584 8.24678 4.17559 8.24678C5.90534 8.24678 7.3073 9.64874 7.3073 11.3785Z"
				fill="#69C52F"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 11.3785C0 13.6848 1.86933 15.5541 4.17561 15.5541C6.48188 15.5541 8.35122 13.6848 8.35122 11.3785C8.35122 9.07225 6.48188 7.20292 4.17561 7.20292C1.86933 7.20292 0 9.07225 0 11.3785ZM4.17561 13.4663C3.02239 13.4663 2.0878 12.5317 2.0878 11.3785C2.0878 10.2253 3.02239 9.29073 4.17561 9.29073C5.32882 9.29073 6.26341 10.2253 6.26341 11.3785C6.26341 12.5317 5.32882 13.4663 4.17561 13.4663Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.5141 32.2703C11.0898 32.9134 10.3708 33.3004 9.60025 33.3004H8.35121C7.77468 33.3004 7.30731 33.7678 7.30731 34.3443V34.3443C7.30731 34.9209 7.77468 35.3882 8.35121 35.3882H10.3308C11.3468 35.3882 12.2949 34.878 12.8544 34.0299L15.7719 29.6083C15.9711 29.3065 16.3086 29.1248 16.6702 29.1248H16.7024C17.279 29.1248 17.7463 28.6575 17.7463 28.0809V28.0809C17.7463 27.5044 17.279 27.037 16.7024 27.037H15.9396C15.3325 27.037 14.766 27.342 14.4317 27.8487L11.5141 32.2703Z"
				fill="#2D64B0"
			/>
			<path
				d="M7.3073 34.3444C7.3073 32.6146 5.90534 31.2127 4.17559 31.2127C2.44584 31.2127 1.04388 32.6146 1.04388 34.3444C1.04388 36.0741 2.44584 37.4761 4.17559 37.4761C5.90534 37.4761 7.3073 36.0741 7.3073 34.3444Z"
				fill="#69C52F"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.17561 36.4322C3.02239 36.4322 2.0878 35.4976 2.0878 34.3444C2.0878 33.1911 3.02239 32.2565 4.17561 32.2565C5.32882 32.2565 6.26341 33.1911 6.26341 34.3444C6.26341 35.4976 5.32882 36.4322 4.17561 36.4322ZM0 34.3444C0 36.6506 1.86933 38.52 4.17561 38.52C6.48188 38.52 8.35122 36.6506 8.35122 34.3444C8.35122 32.0381 6.48188 30.1687 4.17561 30.1687C1.86933 30.1687 0 32.0381 0 34.3444Z"
				fill="#2D64B0"
			/>
			<path
				d="M7.3073 22.8615C7.3073 24.5912 5.90534 25.9932 4.17559 25.9932C2.44584 25.9932 1.04388 24.5912 1.04388 22.8615C1.04388 21.1317 2.44584 19.7298 4.17559 19.7298C5.90534 19.7298 7.3073 21.1317 7.3073 22.8615Z"
				fill="#69C52F"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 22.8614C0 25.1677 1.86933 27.037 4.17561 27.037C6.48188 27.037 8.35122 25.1677 8.35122 22.8614C8.35122 20.5552 6.48188 18.6858 4.17561 18.6858C1.86933 18.6858 0 20.5552 0 22.8614ZM4.17561 24.9492C3.02239 24.9492 2.0878 24.0147 2.0878 22.8614C2.0878 21.7082 3.02239 20.7736 4.17561 20.7736C5.32882 20.7736 6.26341 21.7082 6.26341 22.8614C6.26341 24.0147 5.32882 24.9492 4.17561 24.9492Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.3165 39.1535C12.9851 39.6253 13.0989 40.2763 13.5707 40.6077V40.6077C14.0425 40.9391 14.6936 40.8253 15.025 40.3535L18.1045 35.9694C18.8201 34.9507 19.987 34.3443 21.2319 34.3443H24.0097C24.5863 34.3443 25.0536 33.877 25.0536 33.3004V33.3004C25.0536 32.7239 24.5863 32.2565 24.0097 32.2565H20.2396C18.9367 32.2565 17.7154 32.8911 16.9664 33.9573L13.3165 39.1535Z"
				fill="#2D64B0"
			/>
			<path
				d="M14.6146 43.7394C14.6146 42.0097 13.2126 40.6077 11.4829 40.6077C9.75316 40.6077 8.3512 42.0097 8.3512 43.7394C8.3512 45.4692 9.75316 46.8712 11.4829 46.8712C13.2126 46.8712 14.6146 45.4692 14.6146 43.7394Z"
				fill="#69C52F"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.4829 45.8273C10.3297 45.8273 9.39512 44.8927 9.39512 43.7395C9.39512 42.5863 10.3297 41.6517 11.4829 41.6517C12.6361 41.6517 13.5707 42.5863 13.5707 43.7395C13.5707 44.8927 12.6361 45.8273 11.4829 45.8273ZM7.30731 43.7395C7.30731 46.0458 9.17664 47.9151 11.4829 47.9151C13.7892 47.9151 15.6585 46.0458 15.6585 43.7395C15.6585 41.4332 13.7892 39.5639 11.4829 39.5639C9.17664 39.5639 7.30731 41.4332 7.30731 43.7395Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.7902 22.8614C18.7902 23.438 19.2576 23.9053 19.8341 23.9053V23.9053C20.4107 23.9053 20.878 23.438 20.878 22.8614V22.8614C20.878 22.2849 20.4107 21.8175 19.8341 21.8175V21.8175C19.2576 21.8175 18.7902 22.2849 18.7902 22.8614V22.8614Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.6146 22.8614C14.6146 23.438 15.082 23.9053 15.6585 23.9053V23.9053C16.2351 23.9053 16.7024 23.438 16.7024 22.8614V22.8614C16.7024 22.2849 16.2351 21.8175 15.6585 21.8175V21.8175C15.082 21.8175 14.6146 22.2849 14.6146 22.8614V22.8614Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.9219 17.642C21.9219 17.0654 21.4545 16.598 20.878 16.598V16.598C20.3015 16.598 19.8341 17.0654 19.8341 17.642V17.642C19.8341 18.2185 20.3015 18.6859 20.878 18.6859V18.6859C21.4545 18.6859 21.9219 18.2185 21.9219 17.642V17.642Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.9219 28.081C21.9219 27.5044 21.4545 27.0371 20.878 27.0371V27.0371C20.3015 27.0371 19.8341 27.5044 19.8341 28.081V28.081C19.8341 28.6575 20.3015 29.1249 20.878 29.1249V29.1249C21.4545 29.1249 21.9219 28.6575 21.9219 28.081V28.081Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.1414 22.8614C27.1414 22.2849 26.6741 21.8175 26.0975 21.8175H24.0097C23.4332 21.8175 22.9658 22.2849 22.9658 22.8614V22.8614C22.9658 23.438 23.4332 23.9053 24.0097 23.9053H26.0975C26.6741 23.9053 27.1414 23.438 27.1414 22.8614V22.8614Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M26.0976 28.081C26.0976 27.5044 25.6302 27.0371 25.0537 27.0371V27.0371C24.4771 27.0371 24.0098 27.5044 24.0098 28.081V28.081C24.0098 28.6575 24.4771 29.1249 25.0537 29.1249V29.1249C25.6302 29.1249 26.0976 28.6575 26.0976 28.081V28.081Z"
				fill="#2D64B0"
			/>
		</SVG>
	);
}
