import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "utils/store";
import Base from "components/templates/base";
import CardSlider from "components/sections/card-slider";
import Data from "data/questions";
import { updateAnswers } from "reducer/answers";

import { Wrapper } from "./index.styles";

export default function Questions() {
	const { id } = useParams();
	const dispatch = useDispatch();

	const getQuestion = Data?.filter((item) => item.id === id)[0];
	const usage = useSelector((state: RootState) => state.usage);
	const answers = useSelector((state: RootState) => state.answers);

	const filterAnswer = answers.filter(
		(item: { id: string | undefined }) => item.id === id
	)[0];

	const { title, text, icon, levels, indicators, question, adviser } =
		getQuestion;

	useEffect(() => {
		if (!filterAnswer.updated) {
			dispatch(updateAnswers({ id, value: 50, usage: usage.value }));
		}
	}, [usage, id, dispatch, filterAnswer.updated]);

	return (
		<Base title={title} tagline={text} id={id} icon={icon}>
			<Wrapper>
				<CardSlider
					id={getQuestion.id}
					icon={icon}
					levels={levels}
					indicators={indicators}
					question={question}
					adviser={adviser}
				/>
			</Wrapper>
		</Base>
	);
}
