import { useDispatch, useSelector } from "react-redux";

import { RootState } from "utils/store";
import Base from "components/templates/base";
import CardConfirm from "components/sections/card-confirm";
import { useEffect } from "react";
import { updateTotals } from "reducer/totals";
import { Wrapper } from "./index.styles";

export default function Report() {
	const dispatch = useDispatch();
	const usage = useSelector((state: RootState) => state.usage);
	const answers = useSelector((state: RootState) => state.answers);
	const totals = useSelector((state: RootState) => state.totals);

	useEffect(() => {
		dispatch(updateTotals({ usage, answers }));
	}, [usage, answers, dispatch]);

	const usageTitle = usage.title?.toLowerCase();
	return (
		<Base
			title="Your impact report"
			size="f"
			tagline={
				<p>
					Based on your choices, a customer with <strong>{usageTitle}</strong> could
					expect their annual electricity bill to go from
				</p>
			}
			align="center"
			stats={{ a: totals.currentBill, b: totals.userTotal }}
		>
			<Wrapper>
				<CardConfirm />
			</Wrapper>
		</Base>
	);
}
