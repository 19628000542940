import React from "react";
import { Wrapper } from "./index.styles";

type Props = {
	children: React.ReactNode;
	disabled?: boolean;
	onClick?: (e?: any) => void;
	type?: string;
	variant?: string;
	name?: string;
	size?: "xs" | "s" | "m" | "l" | "default";
	theme?: "success" | "error";
};

export default function Button({
	children,
	disabled = false,
	onClick,
	type = "button",
	variant = "primary",
	name,
	size = "default",
	theme,
}: Props) {
	return (
		<Wrapper
			className={name}
			disabled={disabled}
			name={name}
			variant={variant}
			type={type}
			onClick={onClick}
			size={size}
			theme={theme}
		>
			{children}
		</Wrapper>
	);
}
