import SVG, { Props } from "utils/svg";

export default function Chat({ color, width, height }: Props) {
	return (
		<SVG
			viewBox="0 0 48 48"
			aria-labelledby="Chat"
			color={color}
			height={height}
			width={width}
		>
			<path
				d="M5 2.99998H30C32.209 2.99998 34 4.79098 34 6.99998V26C34 28.209 32.209 30 30 30H15.697L8.719 37.221C8.094 37.868 7 37.425 7 36.526V30H5C2.791 30 1 28.209 1 26V6.99998C1 4.79098 2.791 2.99998 5 2.99998Z"
				fill="#69C52F"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.4381 37.9159C9.43814 37.9159 9.43819 37.9158 9.43823 37.9158L16.1213 31H30C32.7613 31 35 28.7613 35 26V7.00003C35 4.23874 32.7613 2.00003 30 2.00003H5C2.23872 2.00003 0 4.23874 0 7.00003V26C0 28.7613 2.23872 31 5 31H6V36.526C6 38.3229 8.18738 39.2105 9.4381 37.9159ZM14.9779 29.3051L8 36.526V30C8 29.4477 7.55228 29 7 29H5C3.34328 29 2 27.6567 2 26V7.00003C2 5.34331 3.34328 4.00003 5 4.00003H30C31.6567 4.00003 33 5.34331 33 7.00003V26C33 27.6567 31.6567 29 30 29H15.697C15.4258 29 15.1663 29.1101 14.9779 29.3051Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M39.5619 45.9159C40.8126 47.2105 43 46.3229 43 44.526V39C45.7613 39 48 36.7613 48 34V17C48 14.2387 45.7613 12 43 12H36V14H43C44.6567 14 46 15.3433 46 17V34C46 35.6567 44.6567 37 43 37H42C41.4477 37 41 37.4477 41 38V44.526L34.0221 37.3051C33.8337 37.1101 33.5742 37 33.303 37H24C22.3433 37 21 35.6567 21 34V32H19V34C19 36.7613 21.2387 39 24 39H32.8787L39.5619 45.9159C39.5619 45.916 39.5619 45.9159 39.5619 45.9159Z"
				fill="#2D64B0"
			/>
			<circle cx="9.74902" cy="16.2321" r="2.5" fill="#2D64B0" />
			<circle cx="17.749" cy="16.2321" r="2.5" fill="#2D64B0" />
			<circle cx="25.749" cy="16.2321" r="2.5" fill="#2D64B0" />
		</SVG>
	);
}
