import styled from "styled-components";
import { Devices } from "constants";

const { tablet, mobile } = Devices;

export const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	> .l {
		margin: 0 0 25px;
		font-weight: 700;
	}
	@media screen and (max-width: ${mobile}) {
		> .l {
			font-size: 18px;
			min-height: 50px;
			display: flex;
			align-items: flex-end;
		}
	}
`;

export const GuideWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
	> p {
		font-weight: 600;
		&:nth-child(2) {
			text-align: right;
		}
	}
	@media screen and (max-width: ${mobile}) {
		margin-top: 12px;
	}
	width: 100%;
	> p {
		margin: 0;
		font-size: 14px;
		line-height: 16.94px;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
	width: 100%;
	@media screen and (max-width: ${mobile}) {
		flex-direction: column;
		gap: 70px;
		align-items: center;
		> div {
			width: 100%;
			display: flex;
			justify-content: end;
		}
	}
`;
