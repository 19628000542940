import styled, { css } from "styled-components";
import { Devices } from "constants";

const { tablet, mobile } = Devices;

export const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	background-color: var(--white);
	box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
	border-radius: 16px;
	padding: 12px;

	@media screen and (max-width: ${mobile}) {
		height: 100%;
	}
	p {
		margin: 0;
	}
`;

export const TitleWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	margin-bottom: 25px;
	.m {
		font-weight: 800;
		border-left: 1px solid var(--primary);
		padding-left: 8px;
		margin-left: 8px;
		flex-grow: 1;
	}
	@media screen and (max-width: ${mobile}) {
		.m {
			font-size: 18px;
			line-height: 22px;
		}
	}
`;

export const GuideWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 12px;
	width: 100%;
	> p {
		margin: 0;
	}
`;

export const BottomWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 12px;
	padding-top: 12px;
	width: 100%;
	border-top: 1px solid var(--border);
	> p {
		margin: 0;
	}
	${({ centre }) =>
		centre &&
		css`
			> p {
				width: 100%;
				text-align: center;
			}
		`}
`;
