import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import Text from "components/elements/text";
import Button from "components/elements/button";
import Icon from "components/elements/icon";
import QuestionsData from "data/questions";
import { PushIdentifyEvent } from "utils/sitecore-cdp";
import { RootState } from "utils/store";

import { Devices } from "../../../constants";
import CardSliderSecondary from "../card-slider-secondary";

import {
	Wrapper,
	ButtonWrapper,
	CardsWrapper,
	ContentWrapper,
	ButtonWrapperMobile,
} from "./index.styles";
import Carousel from "../carousel";

const { mobile } = Devices;

export default function CardConfirm() {
	const navigate = useNavigate();
	const [summary, setSummary] = useState(false);
	const [success, setSuccess] = useState(false);
	const answers = useSelector((state: RootState) => state.answers);
	const isMobile = useMediaQuery({ query: `(max-width: ${mobile})` });

	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();

		const event = {
			channel: "WEB",
			type: "VIEW",
			currency: "AUD",
			language: "EN",
			page: "Report",
			pos: "betheboss",
			session_data: { answers },
		};

		PushIdentifyEvent({ event, onSuccess: (val: boolean) => setSuccess(val) });
	};

	useEffect(() => {
		if (success) {
			navigate("/success");
		}
	}, [success]);

	return (
		<Wrapper className="card-confirm">
			{summary ? (
				<>
					<ContentWrapper>
						<Text size="s">
							Swipe to review and revise your choices. Press{" "}
							<strong>Approve investments</strong> when you are happy.
						</Text>
						{!isMobile && (
							<Button
								type="button"
								size="m"
								onClick={(e: React.SyntheticEvent) => handleSubmit(e)}
							>
								Approve Investments
							</Button>
						)}
					</ContentWrapper>
					{!isMobile && (
						<CardsWrapper>
							{QuestionsData.map((item) => (
								<CardSliderSecondary key={item.id} {...item} />
							))}
						</CardsWrapper>
					)}
					{isMobile && (
						<>
							<Carousel onMove={false}>
								{QuestionsData.map((item) => (
									<CardSliderSecondary key={item.id} {...item} />
								))}
							</Carousel>
							<ButtonWrapperMobile>
								<Button
									type="button"
									size="m"
									onClick={(e: React.SyntheticEvent) => handleSubmit(e)}
								>
									Approve Investments
								</Button>
							</ButtonWrapperMobile>
						</>
					)}
				</>
			) : (
				<>
					<Text size="l">
						<strong>Are you happy with your choices?</strong>
					</Text>
					<ButtonWrapper>
						<Button
							variant="primary"
							onClick={() => setSummary(true)}
							size="m"
							theme="error"
						>
							No
						</Button>
						<Button
							variant="primary"
							onClick={(e: any) => handleSubmit(e)}
							size="m"
							theme="success"
						>
							Yes
						</Button>
					</ButtonWrapper>
					<Button
						variant="outline"
						onClick={() => navigate("/about-your-bill")}
						size="s"
					>
						<Icon as="warning" color="primary" size="m" />
						<span>About your bill</span>
					</Button>
				</>
			)}
		</Wrapper>
	);
}
