import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Heading from "components/elements/heading";
import Text from "components/elements/text";
import Icon, { IconsType } from "components/elements/icon";

import { updateUsage } from "reducer/usage";
import { resetAnswers } from "reducer/answers";
import { resetTotals } from "reducer/totals";

import { HeadingWrapper, Wrapper } from "./index.styles";

export interface Props {
	icon: IconsType | any;
	title: string;
	text: string;
	usage: number;
}

export default function CardSecondary({ icon, title, text, usage }: Props) {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleClick = () => {
		navigate("/question/1");
		dispatch(updateUsage({ title, value: usage }));
		dispatch(resetAnswers());
		dispatch(resetTotals({}));
	};
	return (
		<Wrapper className="card-secondary item" onClick={() => handleClick()}>
			<HeadingWrapper>
				{icon && <Icon as={icon} size="l" />}
				<Heading as="h3">{title}</Heading>
			</HeadingWrapper>
			<Text>{text}</Text>
			<Text size="m">{usage}kWh per year</Text>
		</Wrapper>
	);
}
