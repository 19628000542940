import { useDispatch, useSelector } from "react-redux";
import { updateAnswers } from "reducer/answers";

import { RootState } from "utils/store";

import { StyledSlider } from "./index.styles";

export type Props = {
	id?: string;
	size?: "s";
	value?: number | unknown;
};

export default function Slider({ id, size, value }: Props) {
	const dispatch = useDispatch();
	const usage = useSelector((state: RootState) => state.usage);

	const handleChange = (a: number) => {
		dispatch(updateAnswers({ id, value: a, usage: usage.value }));
	};
	return (
		<StyledSlider
			className="slider"
			size={size}
			value={value}
			onChange={(a: number) => handleChange(a)}
			thumbClassName="thumb"
			trackClassName="track"
			renderThumb={(props: any, state: any) => (
				<div {...props}>{state.valueNow}</div>
			)}
		/>
	);
}
