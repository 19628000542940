import styled from "styled-components";
import { Devices } from "constants";

const { tablet, mobile } = Devices;

export const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	row-gap: 25px;
	column-gap: 2.5%;
	.item {
		width: 31.43%;
	}
	max-width: ${1020 + 35 * 2}px;
	padding: 0 35px;
	@media screen and (max-width: ${tablet}) {
		max-width: 100%;
		.item {
			width: 48.23%;
		}
		column-gap: 3.4%;
	}
	@media screen and (max-width: ${mobile}) {
		padding: 0 16px;
	}
`;
