export const colors = {
	blue: "#00245D",
	blue900: "#0D7CBF",
	blue800: "#607A8D",
	blue600: "#00245d99",
	blueLight: "#0010a31a",
	grey: "#C4CFD7",
	greyLighter: "#DEE5EA",
	black: "#000000",
	white: "#ffffff",
	green: "#398509",
	red: "#BE123C",

	get primary() {
		return this.blue;
	},
	get paragraph() {
		return this.blue;
	},
	get border() {
		return this.grey;
	},
	get primaryBg() {
		return this.greyLighter;
	},
};

export const fonts = {
	fontInter: "'Inter', sans-serif",
	get primaryFont() {
		return this.fontInter;
	},
};

export const Devices = {
	tablet: "800px",
	mobile: "550px",
};
