import styled from "styled-components";

import { Devices } from "constants";

const { mobile, tablet } = Devices;

export const Wrapper = styled.div`
	margin-top: 40px;
	width: 100%;
	@media screen and (max-width: ${mobile}) {
		margin-top: 12px;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 32px;
	width: 100%;
	max-width: 648px;
	@media screen and (max-width: ${tablet}) {
		max-width: 100%;
	}
	@media screen and (max-width: ${mobile}) {
		margin-top: 22px;
		padding: 0 16px;
	}
`;
