import SVG, { Props } from "utils/svg";

export default function ArrowRight({ color, width, height }: Props) {
	return (
		<SVG
			viewBox="0 0 24 21"
			aria-labelledby="Arrow Right"
			color={color}
			height={height}
			width={width}
		>
			<path d="M13.2045 20.3636L12.2727 19.4432L20.8864 10.8409H0V9.52273H20.8864L12.2727 0.920454L13.2045 0L23.3864 10.1818L13.2045 20.3636Z" />
		</SVG>
	);
}
