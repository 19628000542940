import styled, { css } from "styled-components";

export const Wrapper = styled.div`
	width: 100%;
	box-sizing: content-box;
	display: flex;
	${({ size }) => {
		switch (size) {
			case "l":
				return css`
					max-width: 150px;
				`;
			case "m":
				return css`
					max-width: 140px;
				`;
			case "s":
				return css`
					max-width: 80px;
				`;
			default:
				return css`
					max-width: 117px;
				`;
		}
	}};
`;
