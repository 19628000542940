import styled from "styled-components";

export const InputField = styled.input`
	width: 100%;
	padding: 16px 20px;
	border-radius: var(--borderRadius);
	border: 0;
	background-color: transparent;
	outline: 0;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.3px;
	color: var(--primary);
	&::placeholder,
	&::-webkit-input-placeholder {
		color: var(--blue800);
	}
	&:-ms-input-placeholder {
		color: var(--blue800);
	}
`;

export const Wrapper = styled.div`
	display: flex;
	gap: 10px;
	border: 1px solid var(--border);
	border-radius: 28px;
	overflow: hidden;
	background-color: var(--white);

	&:focus-within {
		border-color: var(--primary);
	}
`;
