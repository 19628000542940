import styled from "styled-components";

import { Devices } from "constants";

const { tablet, mobile } = Devices;

export const Wrapper = styled.div`
	width: 100%;
	margin-top: 19px;
	@media screen and (max-width: ${mobile}) {
		margin-top: 11px;
	}
`;
