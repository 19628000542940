import { getColor } from "utils/helper";
import SVG, { Props } from "utils/svg";

export default function HomeM({ color, width, height }: Props) {
	return (
		<SVG
			viewBox="0 0 48 48"
			aria-labelledby="Home Medium"
			color={color}
			height={height}
			width={width}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.03662 48H42.9262V15.6824H41.3262V46.4H6.63662V15.6824H5.03662V48Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.7725 43.3115C25.7725 43.8638 26.2202 44.3115 26.7725 44.3115H37.2325C37.7848 44.3115 38.2325 43.8638 38.2325 43.3115V35.3291C38.2325 34.7768 37.7848 34.3291 37.2325 34.3291H26.7725C26.2202 34.3291 25.7725 34.7768 25.7725 35.3291V43.3115ZM27.3725 42.7115V35.9291H36.6325V42.7115H27.3725Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.3481 27.3359C10.3481 27.8882 10.7959 28.3359 11.3481 28.3359H22.601C23.1532 28.3359 23.601 27.8882 23.601 27.3359V20.9887C23.601 20.4365 23.1532 19.9887 22.601 19.9887H11.3481C10.7959 19.9887 10.3481 20.4365 10.3481 20.9887V27.3359ZM11.9481 26.7359V21.5887H22.001V26.7359H11.9481Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.5483 27.3359C25.5483 27.8882 25.9961 28.3359 26.5483 28.3359H37.8011C38.3534 28.3359 38.8011 27.8882 38.8011 27.3359V20.9887C38.8011 20.4365 38.3534 19.9887 37.8011 19.9887H26.5483C25.9961 19.9887 25.5483 20.4365 25.5483 20.9887V27.3359ZM27.1483 26.7359V21.5887H37.2011V26.7359H27.1483Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.2749 48H23.9661V35.3296C23.9661 34.7773 23.5184 34.3296 22.9661 34.3296H11.2749C10.7226 34.3296 10.2749 34.7773 10.2749 35.3296V48ZM11.8749 46.4V35.9296H22.3661V46.4H11.8749Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M46.8007 47.1999C46.8007 47.6417 46.4426 47.9999 46.0007 47.9999H1.99922C1.55739 47.9999 1.19922 47.6417 1.19922 47.1999V47.1999C1.19922 46.7581 1.55739 46.3999 1.99922 46.3999H46.0007C46.4426 46.3999 46.8007 46.7581 46.8007 47.1999V47.1999Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.9203 47.5356V34.794H16.3203V47.5356H17.9203Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M32.4021 41.4997C32.7145 41.1873 32.7145 40.6808 32.4021 40.3684L29.8455 37.8117C29.5331 37.4993 29.0265 37.4993 28.7141 37.8117V37.8117C28.4017 38.1242 28.4017 38.6307 28.7141 38.9431L31.2708 41.4997C31.5832 41.8122 32.0897 41.8122 32.4021 41.4997V41.4997Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M33.744 39.4885C34.0564 39.1761 34.0564 38.6696 33.744 38.3571L33.1985 37.8117C32.8861 37.4993 32.3796 37.4993 32.0672 37.8117V37.8117C31.7547 38.1241 31.7547 38.6307 32.0672 38.9431L32.6126 39.4885C32.925 39.8009 33.4315 39.8009 33.744 39.4885V39.4885Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.0008 30.3353C18.0008 29.8934 17.6426 29.5353 17.2008 29.5353H11.6008C11.159 29.5353 10.8008 29.8934 10.8008 30.3353V30.3353C10.8008 30.7771 11.159 31.1353 11.6008 31.1353H17.2008C17.6426 31.1353 18.0008 30.7771 18.0008 30.3353V30.3353Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.49328 13.3524C2.00109 14.1204 2.54727 16.375 4.22551 16.375H42.6501C44.2992 16.375 44.87 14.1822 43.4303 13.3781L25.0022 3.0858C24.5342 2.82442 23.9664 2.81476 23.4898 3.06008L3.49328 13.3524ZM42.6501 14.775H4.22551L24.222 4.4827L42.6501 14.775Z"
				fill="#0072EF"
			/>
		</SVG>
	);
}
