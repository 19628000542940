import { createSlice } from "@reduxjs/toolkit";

export const usageSlice = createSlice({
	name: "usage",
	initialState: {
		title: undefined,
		value: 0,
	},
	reducers: {
		updateUsage: (state, action) => {
			return action.payload;
		},
	},
});

export const { updateUsage } = usageSlice.actions;
