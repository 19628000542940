import Footer from "components/sections/footer";
import Header from "components/sections/header";
import { IconsType } from "components/elements/icon";
import CardTerritory from "components/sections/card-territory";

import { Page, Container, Wrapper, MiddleBar } from "./index.styles";

export type Props = {
	children: React.ReactNode;
	title: string;
	tagline?: React.ReactElement | string;
	stats?: {
		a: number;
		b: number;
	};
	size?: "m" | "f";
	back?: string;
	icon?: IconsType | any;
	id?: string;
	align?: "center";
};

export default function Base({
	title,
	tagline,
	children,
	size,
	back,
	icon,
	stats,
	id,
	align,
}: Props) {
	return (
		<Page>
			<div style={{ width: "100%" }}>
				<Container>
					<Header id={id} title={title} back={back} icon={icon} />
				</Container>
				{tagline && (
					<Container size={stats && "m"}>
						<Wrapper>
							<CardTerritory tagline={tagline} stats={stats} align={align} />
						</Wrapper>
					</Container>
				)}
			</div>
			<Container size={size}>
				<Wrapper>
					<MiddleBar>{children}</MiddleBar>
				</Wrapper>
			</Container>
			<Container className="footer">
				<Wrapper>
					<Footer />
				</Wrapper>
			</Container>
		</Page>
	);
}
