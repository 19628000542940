import { useNavigate } from "react-router-dom";

import Heading from "components/elements/heading";
import Icon, { IconsType } from "components/elements/icon";
import Text from "components/elements/text";

import { Wrapper } from "./index.styles";

export type Props = {
	id?: string;
	title: string;
	back?: string;
	icon?: IconsType;
};

export default function Header({ id, title, back, icon }: Props) {
	const navigate = useNavigate();
	return (
		<Wrapper back={back} onClick={() => back && navigate(-1)}>
			{back && <Icon as="arrow-left" size="s" />}
			{id && <Text size="xs">{id === "6" ? "Extras" : `${id} of 5`}</Text>}
			<Heading>{title}</Heading>
			{icon && <Icon as={icon} size="l" />}
		</Wrapper>
	);
}
