import { useSelector } from "react-redux";

import Text from "components/elements/text";
import Slider from "components/elements/slider";
import Icon from "components/elements/icon";
import { RootState } from "utils/store";
import { addZeroes } from "utils/helper";

import {
	Wrapper,
	TitleWrapper,
	GuideWrapper,
	BottomWrapper,
} from "./index.styles";

export type Props = {
	id: string;
	title: string;
	icon: any;
	indicators: string[];
};

export default function CardSliderSecondary(props: Props) {
	const answers = useSelector((state: RootState) => state?.answers);

	const { id, title, icon, indicators } = props;

	const filterAnswer = answers.filter(
		(item: { id: string }) => item.id === id
	)[0];

	return (
		<Wrapper className="card-slider">
			<TitleWrapper>
				<Text size="xs">{id === "6" ? "Extras" : `${id} of 5`}</Text>
				<Text size="m">{title}</Text>
				<Icon as={icon} />
			</TitleWrapper>
			<Slider size="s" id={id} value={filterAnswer.value} />
			<GuideWrapper>
				<Text>{indicators[0]}</Text>
				<Text>
					<b>{indicators[1]}</b>
				</Text>
			</GuideWrapper>
			<BottomWrapper centre={id === "6"}>
				{id === "6" ? (
					<Text size="m">No Bill impact</Text>
				) : (
					<>
						<Text size="m">Bill impact</Text>
						<Text size="m">
							<strong>+${addZeroes(filterAnswer.level)}</strong>
						</Text>
					</>
				)}
			</BottomWrapper>
		</Wrapper>
	);
}
