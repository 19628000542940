import { InputField, Wrapper } from "./index.styles";

export type InputType = "text" | "email" | "password" | undefined;

export type Props = {
	disabled?: boolean;
	placeholder: string;
	type?: InputType;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	required?: boolean;
	name?: string;
	value?: string;
};

export default function Input(props: Props) {
	const {
		name,
		disabled,
		type = "text",
		onChange,
		required = true,
		value,
	} = props;
	return (
		<Wrapper className="input">
			<InputField
				{...props}
				type={type}
				name={name}
				value={value}
				disabled={disabled}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
				required={required}
			/>
		</Wrapper>
	);
}
