import Base from "components/templates/base";
import CardPrimary from "components/sections/card-primary";

import { Wrapper } from "./index.styles";

const content = (
	<p>
		Your bill is made up of the costs of generating electricity, transporting it,
		Ausgrid&#39;s costs (about 35%) and your retailers costs. Many things can
		impact your overall bill, as the Boss of Ausgrid, you can only change your
		portion.
	</p>
);

export default function AboutBill() {
	return (
		<Base title="Back" back="Back">
			<Wrapper>
				<CardPrimary content={content} title="About your bill" back="Go back" />
			</Wrapper>
		</Base>
	);
}
