import styled from "styled-components";
import { Devices } from "constants";

const { tablet, mobile } = Devices;

export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 24px;
	flex-direction: column;
	p {
		margin: 0;
	}
	strong {
		font-weight: 700;
	}
`;

export const ShareWrapper = styled.div`
	display: grid;
	grid-template-columns: auto auto;
	width: 100%;
	gap: 70px;
	margin-top: 40px;
	text-align: left;
	.input {
		margin: 5px 0 13px;
	}
	@media screen and (max-width: ${tablet}) {
		grid-template-columns: auto;
		> div {
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	button {
		align-self: flex-end;
	}
`;

export const SuccessWrapper = styled.div`
	max-width: 341px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

export const BottomWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 70px;
	text-align: left;
`;

export const Socials = styled.div`
	display: flex;
	gap: 24px;
	margin-top: 12px;
	> a {
		background-color: var(--primary);
		width: 48px;
		height: 48px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 9px;
		border: 1px solid var(--primary);

		.icon {
			width: 100%;
		}
		@media (hover: hover) {
			&:not(:disabled):hover {
				background-color: var(--white);
				svg {
					fill: var(--primary);
				}
			}
		}
		&:not(:disabled):active {
			background-color: var(--primary);
			svg {
				fill: var(--white);
			}
		}
	}
`;
