import { Routes, Route } from "react-router-dom";

import Welcome from "pages/welcome";
import HowToPlay from "pages/how-to-play";
import TellUsAboutYourSelf from "pages/tell-us";
import Questions from "pages/questions";
import Adviser from "pages/adviser";
import AboutBill from "pages/about-bill";
import Report from "pages/report";
import Success from "pages/success";

function App() {
	return (
		<Routes>
			<Route path="/" element={<Welcome />} />
			<Route path="/how-to-play" element={<HowToPlay />} />
			<Route path="/tell-us" element={<TellUsAboutYourSelf />} />
			<Route path="/question/:id" element={<Questions />} />
			<Route path="/adviser/:id" element={<Adviser />} />
			<Route path="/about-your-bill" element={<AboutBill />} />
			<Route path="/report" element={<Report />} />
			<Route path="/success" element={<Success />} />
		</Routes>
	);
}

export default App;
