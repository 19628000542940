import { useSelector } from "react-redux";

import Text from "components/elements/text";
import { RootState } from "utils/store";

import { RightWrapper, Wrapper } from "./index.styles";

export type Props = {
	tagline?: React.ReactElement | string;
	stats?: {
		a: number;
		b: number;
	};
	align?: "center";
};

export default function CardTerritory({ tagline, stats, align }: Props) {
	const usage = useSelector((state: RootState) => state.usage);
	return (
		<Wrapper align={align}>
			<div>
				<Text size="l">{tagline}</Text>
			</div>
			{stats && (
				<RightWrapper>
					<div>
						<Text size="xl">${stats.a}</Text>
						<Text size="m">to</Text>
						<Text size="xl">${stats.b}</Text>
					</div>
					<Text size="s">
						<b>Based on {usage.value}kWh per year usage</b>
					</Text>
				</RightWrapper>
			)}
		</Wrapper>
	);
}
