import styled from "styled-components";

export const CreateSVG = styled.svg.attrs({
	version: "1.1",
	xmlns: "http://www.w3.org/2000/svg",
	xmlnsXlink: "http://www.w3.org/1999/xlink",
})``;

export const SVG = styled(CreateSVG)`
	fill: ${({ color }) => (color ? `var(--${[color]})` : "var(--grey)")};
	width: ${({ width }) => width || "100%"};
	height: ${({ height }) => height || "100%"};
`;
