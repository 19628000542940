import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import {
	persistStore,
	persistCombineReducers,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "reduxjs-toolkit-persist";
import storage from "reduxjs-toolkit-persist/lib/storage";

import { usageSlice } from "reducer/usage";
import { answersSlice } from "reducer/answers";
import { totalsSlice } from "reducer/totals";

const persistConfig = {
	key: "root",
	storage,
};

const _persistedReducer = persistCombineReducers(persistConfig, {
	usage: usageSlice.reducer,
	answers: answersSlice.reducer,
	totals: totalsSlice.reducer,
});

const store = configureStore({
	reducer: _persistedReducer,
	middleware: getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		},
	}),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export default store;
