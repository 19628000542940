import Base from "components/templates/base";

import CardSuccess from "components/sections/card-success";

import { Wrapper } from "./index.styles";

export default function Success() {
	return (
		<Base title="Thank you" size="m">
			<Wrapper>
				<CardSuccess />
			</Wrapper>
		</Base>
	);
}
