import styled, { css } from "styled-components";

export const Wrapper = styled.header`
	background: linear-gradient(
		261.47deg,
		#00245d 37.44%,
		#0068a9 80.27%,
		#0068a9 80.32%,
		#0068a9 81.02%
	);
	width: 100%;
	padding-left: 16px;
	padding: 13px 16px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-radius: 16px;
	color: var(--white);
	position: relative;
	.heading {
		margin: 0;
		flex-grow: 1;
	}
	.text {
		margin: 0 8px 0 0;
		padding-right: 8px;
		border-right: 1px solid var(--white);
	}
	.icon {
		&.l {
			position: absolute;
			top: 6px;
			right: 6px;
		}
		&.s {
			margin-right: 15px;
		}
	}
	${({ back }) =>
		back &&
		css`
			cursor: pointer;
		`}
`;
