import { getColor } from "utils/helper";
import SVG, { Props } from "utils/svg";

export default function AusGrid({ color, width, height }: Props) {
	return (
		<SVG
			viewBox="0 0 117 32"
			aria-labelledby="Go.Thrive"
			color={color}
			height={height}
			width={width}
		>
			<path
				d="M43.2623 9.42928H43.1649L40.5383 16.7253H45.8649L43.2623 9.42928ZM51.8236 23.1226C51.8236 23.4146 51.6303 23.5613 51.2156 23.5613H49.0023C48.4436 23.5613 48.1263 23.3173 47.9569 22.8053L46.7889 19.5226H39.5649L38.3983 22.8053C38.2276 23.3173 37.9116 23.5613 37.3516 23.5613H35.4063C35.0169 23.5613 34.8223 23.4146 34.8223 23.1226C34.8223 23.0253 34.8716 22.8786 34.9196 22.7573L41.0009 6.87461C41.1956 6.36395 41.5116 6.07195 42.0223 6.07195H44.6249C45.1116 6.07195 45.4023 6.36395 45.5969 6.87461L51.7276 22.7813C51.7756 22.9039 51.8236 23.0493 51.8236 23.1226Z"
				fill={getColor({ colorA: color, colorB: "#231F20" })}
			/>
			<path
				d="M55.3754 17.5281C55.3754 19.7907 55.934 21.0787 57.71 21.0787C59.7527 21.0787 60.8474 19.3521 60.8474 16.7014V11.8134C60.8474 11.2774 61.0914 11.0334 61.5767 11.0334H63.426C63.9114 11.0334 64.154 11.2774 64.154 11.8134V22.7814C64.154 23.3174 63.9114 23.5614 63.426 23.5614H61.674C61.1887 23.5614 60.9447 23.3174 60.9447 22.7814V21.7361H60.798C59.802 23.2681 58.4887 23.8521 56.7367 23.8521C53.0887 23.8521 52.0674 21.2734 52.0674 18.4521V11.8134C52.0674 11.2774 52.31 11.0334 52.7727 11.0334H54.6207C55.1314 11.0334 55.3754 11.2774 55.3754 11.8134V17.5281Z"
				fill={getColor({ colorA: color, colorB: "#231F20" })}
			/>
			<path
				d="M73.7617 12.1045L73.495 13.2952C73.4217 13.6365 73.275 13.7579 72.959 13.7579C72.4483 13.7579 71.767 13.1739 70.3577 13.1739C69.335 13.1739 68.6537 13.5645 68.6537 14.2205C68.6537 15.0712 69.7723 15.4605 71.5003 16.1899C73.6163 17.0912 74.467 18.1605 74.467 19.9365C74.467 22.8059 71.9137 23.8512 69.311 23.8512C68.119 23.8512 66.855 23.6085 66.027 23.1952C65.5897 23.0005 65.4923 22.7085 65.5403 22.3445L65.687 21.1765C65.7603 20.7632 65.9297 20.5925 66.271 20.5925C66.7323 20.5925 67.511 21.4205 69.2137 21.4205C70.235 21.4205 71.2083 21.1032 71.2083 20.1299C71.2083 19.3032 70.3817 18.9379 68.411 18.1605C66.5617 17.4072 65.4443 16.3845 65.4443 14.4392C65.4443 11.9099 67.535 10.7432 70.235 10.7432C71.135 10.7432 72.351 10.9365 73.275 11.3259C73.7617 11.5205 73.859 11.6659 73.7617 12.1045Z"
				fill={getColor({ colorA: color, colorB: "#231F20" })}
			/>
			<path
				d="M84.8521 17.2603C84.8521 14.8523 83.5628 13.3203 81.7641 13.3203C79.7681 13.3203 78.7468 15.1697 78.7468 17.187C78.7468 19.231 79.7201 21.031 81.7135 21.031C83.7335 21.031 84.8521 19.3763 84.8521 17.2603ZM88.1601 22.6603C88.1601 27.111 85.7521 29.0817 81.0828 29.0817C79.8175 29.0817 78.2855 28.8137 77.2401 28.3763C76.5335 28.1083 76.4361 27.9137 76.5095 27.3297L76.6801 26.1617C76.7521 25.7977 76.8988 25.627 77.2401 25.627C77.8228 25.627 78.7961 26.527 81.0588 26.527C83.6121 26.527 84.8521 25.2137 84.8521 22.587V21.687H84.7788C83.8801 23.099 82.4935 23.5603 80.8148 23.5603C77.1415 23.5603 75.2935 20.6417 75.2935 17.115C75.2935 13.9537 77.1175 10.743 80.7895 10.743C82.5655 10.743 83.9281 11.3257 84.8521 12.5417H84.9735V11.8123C84.9735 11.2777 85.2175 11.0337 85.7268 11.0337H87.4548C87.9161 11.0337 88.1601 11.2777 88.1601 11.8123V22.6603Z"
				fill={getColor({ colorA: color, colorB: "#231F20" })}
			/>
			<path
				d="M90.4461 23.5609C89.9834 23.5609 89.7407 23.3169 89.7407 22.7809V11.8129C89.7407 11.2769 89.9834 11.0342 90.4461 11.0342H92.1967C92.7087 11.0342 92.9514 11.2769 92.9514 11.8129V12.7609H93.0487C93.8514 11.3502 94.7754 10.7182 96.2594 10.7182C96.7461 10.7182 97.1834 10.7662 97.4021 10.8396C97.6701 10.9369 97.8394 11.0836 97.8394 11.5689V13.0529C97.8394 13.5396 97.6207 13.7102 97.2074 13.7102C96.8914 13.7102 96.5754 13.5876 95.9181 13.5876C93.8514 13.5876 93.0487 15.4849 93.0487 17.8929V22.7809C93.0487 23.3169 92.8047 23.5609 92.2954 23.5609H90.4461Z"
				fill={getColor({ colorA: color, colorB: "#231F20" })}
			/>
			<path
				d="M98.8608 11.8128C98.8608 11.2768 99.1048 11.0341 99.5902 11.0341H101.439C101.926 11.0341 102.169 11.2768 102.169 11.8128V22.7808C102.169 23.3168 101.926 23.5608 101.439 23.5608H99.5902C99.1048 23.5608 98.8608 23.3168 98.8608 22.7808V11.8128ZM100.491 4.80746C101.535 4.80746 102.387 5.65946 102.387 6.70479C102.387 7.75012 101.535 8.60212 100.491 8.60212C99.4702 8.60212 98.6182 7.75012 98.6182 6.70479C98.6182 5.65946 99.4702 4.80746 100.491 4.80746Z"
				fill={getColor({ colorA: color, colorB: "#231F20" })}
			/>
			<path
				d="M113.211 17.3341C113.211 14.9501 111.971 13.3688 110.123 13.3688C108.127 13.3688 107.106 15.2181 107.106 17.2368C107.106 19.2794 108.079 21.2248 110.074 21.2248C112.044 21.2248 113.211 19.4741 113.211 17.3341ZM113.235 21.8821C112.359 23.2194 110.924 23.8514 109.222 23.8514C105.452 23.8514 103.652 20.7141 103.652 17.1141C103.652 13.9288 105.526 10.7421 109.076 10.7421C110.876 10.7421 112.214 11.3261 113.138 12.5421H113.211V5.53811C113.211 5.00211 113.455 4.73411 113.966 4.73411H115.814C116.275 4.73411 116.518 5.00211 116.518 5.53811V22.7808C116.518 23.3168 116.275 23.5608 115.814 23.5608H114.135C113.624 23.5608 113.38 23.3168 113.38 22.7808V21.8821H113.235Z"
				fill={getColor({ colorA: color, colorB: "#231F20" })}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.1562 6.34449C21.7616 6.03649 21.1002 5.77116 20.6562 6.21382C20.3522 6.52049 19.4096 7.69249 19.1829 8.06582C18.9029 8.52982 19.2296 9.08849 19.5482 9.35649C21.0389 10.6192 25.5776 13.8405 31.0389 20.4818C31.0496 20.4912 31.0562 20.5018 31.0642 20.5112C31.3056 20.8405 32.0176 21.0685 32.1976 20.3112C32.2589 20.0472 32.5669 18.6312 32.6989 17.8032C32.8842 16.6312 32.4016 16.0605 32.1269 15.7552C31.6789 15.2565 26.2082 9.48449 22.1562 6.34449Z"
				fill={getColor({ colorA: color, colorB: "#004491" })}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.1554 0.190979C15.678 0.928313 12.63 2.88831 12.4194 3.01498C11.69 3.45898 11.11 4.12965 12.2314 4.76565C14.27 5.92831 16.5474 7.39231 17.1167 7.67098C18.1887 8.19498 18.5127 7.71898 19.2927 6.72831C20.174 5.60165 21.0194 4.61898 21.8847 3.49631C22.1314 3.17098 22.9167 2.41231 22.1847 1.85498C21.8487 1.59765 19.6567 0.481646 18.9394 0.209646C18.5287 0.0496461 17.8554 -0.159687 17.1554 0.190979Z"
				fill={getColor({ colorA: color, colorB: "#609E00" })}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.54042 28.1673C6.62042 26.9967 6.85775 24.9687 7.44842 24.2007C8.00442 23.478 16.9471 11.2873 17.1391 11.018C17.8124 10.0593 18.5578 10.3087 18.9391 10.5687C20.0204 11.306 23.1791 13.994 24.1724 15.066C24.8444 15.7913 24.1897 16.7713 23.8791 17.67C23.3177 19.298 18.6951 27.622 16.6058 30.7367C16.6058 30.7367 15.5631 32.5353 14.0124 31.5487C13.3818 31.1473 8.73908 28.2887 8.54042 28.1673Z"
				fill={getColor({ colorA: color, colorB: "#004491" })}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.06228 3.45065C2.91161 4.06798 1.30628 4.98398 0.411611 5.67331C-0.413722 6.24531 0.114278 6.93865 1.00761 7.31598C4.16094 8.65731 10.9116 12.5013 12.3009 13.364C12.8409 13.6973 13.5369 14.0093 14.2209 13.2933C14.5103 12.9893 16.0903 10.976 16.4343 10.52C17.0196 9.74131 16.7063 9.06131 16.2903 8.75998C13.4329 6.68665 8.87694 4.38531 6.01028 3.20398C5.55561 3.01465 4.85961 2.99465 4.06228 3.45065Z"
				fill={getColor({ colorA: color, colorB: "#2B93DD" })}
			/>
		</SVG>
	);
}
