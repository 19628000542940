import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "utils/store";

import Button from "components/elements/button";
import Icon from "components/elements/icon";
import Input from "components/elements/input";
import Text from "components/elements/text";

import { PushIdentifyEvent } from "utils/sitecore-cdp";

import {
	BottomWrapper,
	Form,
	ShareWrapper,
	Socials,
	SuccessWrapper,
	Wrapper,
} from "./index.styles";

export default function CardSuccess() {
	const totals = useSelector((state: RootState) => state.totals);
	const [formData, setFormData] = useState({ email: "" });
	const [success, setSuccess] = useState(false);
	const onChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
		setFormData({ ...formData, [e.target.name]: e.target.value });
	const { email } = formData;
	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		const event = {
			channel: "WEB",
			type: "IDENTITY",
			currency: "AUD",
			language: "EN",
			pos: "betheboss",
			email,
			identifiers: [
				{
					provider: "email",
					id: email,
				},
			],
		};

		PushIdentifyEvent({ event, onSuccess: (val: boolean) => setSuccess(val) });
	};

	useEffect(() => {
		if (success) {
			setFormData({
				email: "",
			});
		}
	}, [success]);

	return (
		<Wrapper>
			<Text size="l">Congratulations, you are a...</Text>
			<Text size="xl">
				<strong>{totals.investorType.title}</strong>
			</Text>
			<Text size="l">{totals.investorType.description}</Text>
			<ShareWrapper className="share">
				<div>
					<Text size="m">
						<strong>Invite others to play</strong>
					</Text>
					<Socials>
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.facebook.com/sharer/sharer.php?u=https://betheboss.ausgrid.com.au"
						>
							<Icon as="facebook" color="white" />
						</a>
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.linkedin.com/cws/share?url=https://betheboss.ausgrid.com.au"
						>
							<Icon as="linkedin" color="white" />
						</a>

						<a
							target="_blank"
							rel="noreferrer"
							href="mailto:?subject=BeTheBoss&body=https://betheboss.ausgrid.com.au"
						>
							<Icon as="envelope" color="white" />
						</a>
					</Socials>
				</div>
				{!success ? (
					<Form onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}>
						<Text size="m">
							<strong>Want to keep up to date?</strong>
						</Text>
						<Input
							required
							type="text"
							name="email"
							value={email}
							placeholder="Email address"
							onChange={onChange}
						/>
						<Button size="s" type="submit">
							Get updates
						</Button>
					</Form>
				) : (
					<SuccessWrapper>
						<Text size="m">
							Thank you for subscribing for updates. We will be in touch soon.
						</Text>
					</SuccessWrapper>
				)}
			</ShareWrapper>
			<BottomWrapper>
				<Text size="m">
					Find out more about Ausgrid’s vision for an affordable, resilient, net zero
					future at{" "}
					<a href="https://yoursay.ausgrid.com.au" rel="noreferrer" target="_blank">
						<strong>yoursay.ausgrid.com.au</strong>
					</a>
					.
				</Text>
			</BottomWrapper>
		</Wrapper>
	);
}
