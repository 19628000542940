import { getColor } from "utils/helper";
import SVG, { Props } from "utils/svg";

export default function HomeL({ color, width, height }: Props) {
	return (
		<SVG
			viewBox="0 0 48 48"
			aria-labelledby="Home Large"
			color={color}
			height={height}
			width={width}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.19141 48H43.081V15.6824H41.481V46.4H6.79141V15.6824H5.19141V48Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M46.9218 47.1984C46.9218 47.6403 46.5637 47.9984 46.1218 47.9984H2.12031C1.67848 47.9984 1.32031 47.6403 1.32031 47.1984C1.32031 46.7566 1.67848 46.3984 2.12031 46.3984H46.1218C46.5637 46.3984 46.9218 46.7566 46.9218 47.1984Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.0751 47.7085V35.594H16.4751V47.7085H18.0751Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.58691 13.6709V15.0117H4.18691V13.6709H2.58691Z"
				fill="#0072EF"
			/>
			<path
				d="M16.555 9.67289L2.11158 13.7085C0.531539 14.1505 0.84623 16.4822 2.49166 16.4822H43.0093C43.7895 16.4822 44.4221 15.8496 44.4221 15.0694V3.74862C44.4221 2.81225 43.5288 2.13731 42.6293 2.38747L40.1729 3.07381C40.4147 3.4484 40.555 3.89462 40.555 4.37362V4.62833L42.8221 3.99489V14.8822H3.85666L16.6168 11.3169C16.5764 11.1423 16.555 10.9605 16.555 10.7736V9.67289Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.9557 1.97363C17.6302 1.97363 16.5557 3.04815 16.5557 4.37363V10.7736C16.5557 12.0991 17.6302 13.1736 18.9557 13.1736H38.1557C39.4811 13.1736 40.5557 12.0991 40.5557 10.7736V4.37363C40.5557 3.04815 39.4811 1.97363 38.1557 1.97363H18.9557ZM22.1557 3.57363L18.9557 3.57363C18.5138 3.57363 18.1557 3.93181 18.1557 4.37363V6.77363H22.1557V3.57363ZM23.7557 3.57363V6.77363H27.7557V3.57363L23.7557 3.57363ZM23.7557 8.37363H27.7557V11.5736H23.7557V8.37363ZM33.3557 11.5736H29.3557V8.37363H33.3557V11.5736ZM34.9557 11.5736H38.1557C38.5975 11.5736 38.9557 11.2155 38.9557 10.7736V8.37363H34.9557V11.5736ZM34.9557 6.77363H38.9557V4.37363C38.9557 3.93181 38.5975 3.57363 38.1557 3.57363H34.9557V6.77363ZM33.3557 3.57363L29.3557 3.57363V6.77363H33.3557V3.57363ZM22.1557 8.37363H18.1557V10.7736C18.1557 11.2155 18.5138 11.5736 18.9557 11.5736H22.1557V8.37363Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.9272 43.3105C25.9272 43.8628 26.375 44.3105 26.9272 44.3105H37.3873C37.9395 44.3105 38.3873 43.8628 38.3873 43.3105V35.3281C38.3873 34.7759 37.9395 34.3281 37.3873 34.3281H26.9272C26.375 34.3281 25.9272 34.7759 25.9272 35.3282V43.3105ZM27.5272 42.7105V35.9282H36.7873V42.7105H27.5272Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.5029 26.5347C10.5029 27.087 10.9506 27.5347 11.5029 27.5347H22.7557C23.308 27.5347 23.7557 27.087 23.7557 26.5347V20.1875C23.7557 19.6352 23.308 19.1875 22.7557 19.1875H11.5029C10.9506 19.1875 10.5029 19.6352 10.5029 20.1875V26.5347ZM12.1029 25.9347V20.7875H22.1557V25.9347H12.1029Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.7031 26.5347C25.7031 27.087 26.1508 27.5347 26.7031 27.5347H37.9559C38.5082 27.5347 38.9559 27.087 38.9559 26.5347V20.1875C38.9559 19.6352 38.5082 19.1875 37.9559 19.1875H26.7031C26.1508 19.1875 25.7031 19.6352 25.7031 20.1875V26.5347ZM27.3031 25.9347V20.7875H37.3559V25.9347H27.3031Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.4297 47.9985H24.1209V35.3281C24.1209 34.7758 23.6732 34.3281 23.1209 34.3281H11.4297C10.8774 34.3281 10.4297 34.7758 10.4297 35.3281V47.9985ZM12.0297 46.3985V35.9281H22.5209V46.3985H12.0297Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M32.5569 41.4988C32.8693 41.1863 32.8693 40.6798 32.5569 40.3674L30.0003 37.8108C29.6879 37.4983 29.1813 37.4983 28.8689 37.8108C28.5565 38.1232 28.5565 38.6297 28.8689 38.9421L31.4255 41.4988C31.738 41.8112 32.2445 41.8112 32.5569 41.4988Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M33.8987 39.4875C34.2112 39.1751 34.2112 38.6686 33.8987 38.3562L33.3533 37.8107C33.0409 37.4983 32.5344 37.4983 32.2219 37.8107C31.9095 38.1232 31.9095 38.6297 32.2219 38.9421L32.7674 39.4875C33.0798 39.8 33.5863 39.8 33.8987 39.4875Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.1556 29.534C18.1556 29.0922 17.7974 28.734 17.3556 28.734H11.7556C11.3137 28.734 10.9556 29.0922 10.9556 29.534C10.9556 29.9758 11.3137 30.334 11.7556 30.334H17.3556C17.7974 30.334 18.1556 29.9758 18.1556 29.534Z"
				fill="#0072EF"
			/>
		</SVG>
	);
}
