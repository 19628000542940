import { getColor } from "utils/helper";
import SVG, { Props } from "utils/svg";

export default function HomeS({ color, width, height }: Props) {
	return (
		<SVG
			viewBox="0 0 48 48"
			aria-labelledby="Home Small"
			color={color}
			height={height}
			width={width}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.85781 6.09378V14.7549H7.25781V5.29378C7.25781 4.85195 7.61598 4.49378 8.05781 4.49378H13.4097C13.8515 4.49378 14.2097 4.85195 14.2097 5.29378V11.6928H12.6097V6.09378H8.85781Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.83545 42.7542C6.83545 43.196 7.19362 43.5542 7.63545 43.5542H40.128C40.5698 43.5542 40.928 43.196 40.928 42.7542V18.2899H39.328V41.9542H8.43545V18.2899H6.83545V42.7542Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.0957 32.8162C27.0957 33.258 27.4539 33.6162 27.8957 33.6162H35.8081C36.25 33.6162 36.6081 33.258 36.6081 32.8162V24.9038C36.6081 24.462 36.25 24.1038 35.8081 24.1038H27.8957C27.4539 24.1038 27.0957 24.462 27.0957 24.9038V32.8162ZM28.6957 32.0162V25.7038H35.0081V32.0162H28.6957Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.8062 42.7552C11.8062 43.197 12.1643 43.5552 12.6062 43.5552H20.3602C20.8021 43.5552 21.1602 43.197 21.1602 42.7552V25.3759C21.1602 24.934 20.8021 24.5759 20.3602 24.5759H12.6062C12.1643 24.5759 11.8062 24.934 11.8062 25.3759V42.7552ZM13.4062 41.9552V26.1759H19.5602V41.9552H13.4062Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M46.1464 42.8029C46.1464 43.2448 45.7882 43.6029 45.3464 43.6029H3.49482C3.053 43.6029 2.69482 43.2448 2.69482 42.8029C2.69482 42.3611 3.053 42.0029 3.49482 42.0029H45.3464C45.7882 42.0029 46.1464 42.3611 46.1464 42.8029Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.97314 18.2898C7.97314 18.7317 8.33132 19.0898 8.77314 19.0898H26.1525C26.5943 19.0898 26.9525 18.7317 26.9525 18.2898C26.9525 17.848 26.5943 17.4898 26.1525 17.4898H8.77314C8.33132 17.4898 7.97314 17.848 7.97314 18.2898Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.8003 14.7547C12.8003 15.1965 13.1585 15.5547 13.6003 15.5547H20.3589C20.8007 15.5547 21.1589 15.1965 21.1589 14.7547C21.1589 14.3129 20.8007 13.9547 20.3589 13.9547H13.6003C13.1585 13.9547 12.8003 14.3129 12.8003 14.7547Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.0835 37.7617H35.8076V36.1617H28.0835V37.7617Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.57444 21.1272C3.8134 21.4988 4.30834 21.6064 4.67996 21.3675L24.0732 8.89885L43.4665 21.3675C43.8382 21.6064 44.3331 21.4988 44.5721 21.1272L46.0194 18.8766C46.1341 18.6981 46.1733 18.4814 46.1282 18.274C46.0832 18.0667 45.9576 17.8857 45.7791 17.771L24.558 4.12727C24.4083 4.03098 24.2384 3.99095 24.0734 4.00183C23.9039 3.99093 23.7341 4.03427 23.5894 4.12726L2.36738 17.771C2.18889 17.8857 2.0633 18.0667 2.01825 18.274C1.97319 18.4814 2.01236 18.6981 2.12713 18.8766L3.57444 21.1272ZM24.0737 5.71805L44.2409 18.6841L43.659 19.589L24.5059 7.27485C24.2423 7.10541 23.9041 7.10541 23.6406 7.27485L4.48752 19.589L3.90564 18.6842L24.0737 5.71805Z"
				fill="#0072EF"
			/>
		</SVG>
	);
}
