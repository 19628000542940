import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Text from "components/elements/text";
import Slider from "components/elements/slider";
import Button from "components/elements/button";
import Icon, { IconsType } from "components/elements/icon";
import { RootState } from "utils/store";

import { Wrapper, ButtonWrapper, GuideWrapper } from "./index.styles";

type Level = {
	usage: number;
	values: number[];
};

export type Props = {
	id: string;
	question: string;
	indicators: string[];
	icon: IconsType | any;
	adviser: {
		icon: IconsType | any;
		text: React.ReactNode | string;
	};
	levels: Level[];
};

export default function CardSlider(props: Props) {
	const navigate = useNavigate();
	const answers = useSelector((state: RootState) => state.answers);

	const { id, icon, levels, indicators, question, adviser } = props;

	const next = () => {
		const nextId = Number(id) + 1;

		if (id === "6") {
			navigate("/report");
		} else {
			navigate(`/question/${nextId}`);
		}
	};

	const filterAnswer = answers.filter(
		(item: { id: string }) => item.id === id
	)[0];

	const goToAdviser = () => {
		navigate(`/adviser/${id}`);
	};
	return (
		<Wrapper className="card-slider">
			<Text size="l">{question}</Text>
			<Slider id={id} value={filterAnswer.value} />
			<GuideWrapper>
				<Text size="m">{indicators[0]}</Text>
				<Text size="m">{indicators[1]}</Text>
			</GuideWrapper>
			<ButtonWrapper>
				<Button variant="secondary" onClick={() => goToAdviser()}>
					<Icon as={adviser.icon} color="green" />
					<span>Ask the adviser</span>
				</Button>
				<div>
					<Button variant="primary" onClick={() => next()} size="s">
						Next question <Icon size="xs" as="arrow-right" color="white" />
					</Button>
				</div>
			</ButtonWrapper>
		</Wrapper>
	);
}
