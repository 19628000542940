import { getColor } from "utils/helper";
import SVG, { Props } from "utils/svg";

export default function SME({ color, width, height }: Props) {
	return (
		<SVG
			viewBox="0 0 48 48"
			aria-labelledby="SME"
			color={color}
			height={height}
			width={width}
		>
			<path d="M40.8002 47.2129H7.2002V19.6719H40.8002V47.2129Z" fill="white" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.3999 47.2131C6.3999 47.6477 6.75807 48 7.1999 48H40.7999C41.2417 48 41.5999 47.6477 41.5999 47.2131V19.6721C41.5999 19.2375 41.2417 18.8852 40.7999 18.8852H7.1999C6.75807 18.8852 6.3999 19.2375 6.3999 19.6721V47.2131ZM7.9999 46.4262V20.459H39.9999V46.4262H7.9999Z"
				fill="#0072EF"
			/>
			<path
				d="M13.2305 19.6719H5.6001L8.86473 12.5899H15.2001L13.2305 19.6719Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.92739 20.0984C5.07468 20.3232 5.32794 20.459 5.59981 20.459H13.2302C13.5907 20.459 13.9067 20.2218 14.0018 19.8797L15.9715 12.7978C16.0373 12.5611 15.9871 12.3078 15.8358 12.1127C15.6844 11.9177 15.4493 11.8032 15.1998 11.8032H8.86444C8.55045 11.8032 8.2655 11.9839 8.13584 12.2652L4.87121 19.3472C4.75894 19.5907 4.7801 19.8737 4.92739 20.0984ZM6.84094 18.8852L9.3801 13.377H14.1516L12.6196 18.8852H6.84094Z"
				fill="#0072EF"
			/>
			<path
				d="M20.1629 19.6719H12.7998L14.6859 12.5899H20.7998L20.1629 19.6719Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.1667 20.1529C12.3182 20.3459 12.5521 20.459 12.8 20.459H20.1631C20.5776 20.459 20.9234 20.1476 20.96 19.7414L21.5969 12.6595C21.6167 12.4396 21.5416 12.2216 21.3901 12.0588C21.2385 11.896 21.0244 11.8032 20.8 11.8032H14.6861C14.3223 11.8032 14.0044 12.0446 13.9122 12.3907L12.0261 19.4727C11.9633 19.7086 12.0152 19.9598 12.1667 20.1529ZM13.8366 18.8852L15.3035 13.377H19.9261L19.4307 18.8852H13.8366Z"
				fill="#0072EF"
			/>
			<path
				d="M27.2 19.6719H20L20.6148 12.5899H26.5928L27.2 19.6719Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.4109 20.2043C19.5624 20.3666 19.7762 20.459 20.0002 20.459H27.2002C27.4241 20.459 27.6377 20.3667 27.7892 20.2046C27.9407 20.0425 28.0162 19.8254 27.9974 19.606L27.3902 12.524C27.3552 12.1165 27.0088 11.8032 26.593 11.8032H20.615C20.1995 11.8032 19.8532 12.116 19.8179 12.5232L19.2031 19.6052C19.184 19.8247 19.2594 20.042 19.4109 20.2043ZM20.8714 18.8852L21.3496 13.377H25.8576L26.3299 18.8852H20.8714Z"
				fill="#0072EF"
			/>
			<path
				d="M35.2002 19.6719H27.8358L27.2002 12.5899H33.3152L35.2002 19.6719Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.0386 19.7413C27.0751 20.1475 27.4209 20.459 27.8355 20.459H35.1999C35.4478 20.459 35.6817 20.3459 35.8332 20.1529C35.9847 19.9599 36.0366 19.7087 35.9738 19.4728L34.0888 12.3908C33.9967 12.0447 33.6787 11.8032 33.3149 11.8032H27.1999C26.9755 11.8032 26.7614 11.896 26.6099 12.0587C26.4583 12.2215 26.3833 12.4395 26.403 12.6593L27.0386 19.7413ZM28.568 18.8852L28.0736 13.377H32.6974L34.1635 18.8852H28.568Z"
				fill="#0072EF"
			/>
			<path
				d="M42.4001 19.6719H35.4056L33.6001 12.5899H39.4075L42.4001 19.6719Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M34.6293 19.8635C34.7186 20.2135 35.0384 20.459 35.4053 20.459H42.3998C42.6672 20.459 42.9169 20.3276 43.0653 20.1089C43.2137 19.8901 43.2412 19.6129 43.1385 19.3701L40.146 12.2881C40.0219 11.9946 39.7304 11.8032 39.4072 11.8032H33.5998C33.3534 11.8032 33.1207 11.915 32.9691 12.1061C32.8175 12.2972 32.7639 12.5464 32.8238 12.7815L34.6293 19.8635ZM36.0295 18.8852L34.6252 13.377H38.8734L41.201 18.8852H36.0295Z"
				fill="#0072EF"
			/>
			<path
				d="M13.2305 19.6719H5.6001L8.86473 12.5899H15.2001L13.2305 19.6719Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.92739 20.0984C5.07468 20.3232 5.32794 20.459 5.59981 20.459H13.2302C13.5907 20.459 13.9067 20.2218 14.0018 19.8797L15.9715 12.7978C16.0373 12.5611 15.9871 12.3078 15.8358 12.1127C15.6844 11.9177 15.4493 11.8032 15.1998 11.8032H8.86444C8.55045 11.8032 8.2655 11.9839 8.13584 12.2652L4.87121 19.3472C4.75894 19.5907 4.7801 19.8737 4.92739 20.0984ZM6.84094 18.8852L9.3801 13.377H14.1516L12.6196 18.8852H6.84094Z"
				fill="#0072EF"
			/>
			<path
				d="M20.1629 19.6719H12.7998L14.6859 12.5899H20.7998L20.1629 19.6719Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.1667 20.1529C12.3182 20.3459 12.5521 20.459 12.8 20.459H20.1631C20.5776 20.459 20.9234 20.1476 20.96 19.7414L21.5969 12.6595C21.6167 12.4396 21.5416 12.2216 21.3901 12.0588C21.2385 11.896 21.0244 11.8032 20.8 11.8032H14.6861C14.3223 11.8032 14.0044 12.0446 13.9122 12.3907L12.0261 19.4727C11.9633 19.7086 12.0152 19.9598 12.1667 20.1529ZM13.8366 18.8852L15.3035 13.377H19.9261L19.4307 18.8852H13.8366Z"
				fill="#0072EF"
			/>
			<path
				d="M27.2 19.6719H20L20.6148 12.5899H26.5928L27.2 19.6719Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.4109 20.2043C19.5624 20.3666 19.7762 20.459 20.0002 20.459H27.2002C27.4241 20.459 27.6377 20.3667 27.7892 20.2046C27.9407 20.0425 28.0162 19.8254 27.9974 19.606L27.3902 12.524C27.3552 12.1165 27.0088 11.8032 26.593 11.8032H20.615C20.1995 11.8032 19.8532 12.116 19.8179 12.5232L19.2031 19.6052C19.184 19.8247 19.2594 20.042 19.4109 20.2043ZM20.8714 18.8852L21.3496 13.377H25.8576L26.3299 18.8852H20.8714Z"
				fill="#0072EF"
			/>
			<path
				d="M35.2002 19.6719H27.8358L27.2002 12.5899H33.3152L35.2002 19.6719Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.0386 19.7413C27.0751 20.1475 27.4209 20.459 27.8355 20.459H35.1999C35.4478 20.459 35.6817 20.3459 35.8332 20.1529C35.9847 19.9599 36.0366 19.7087 35.9738 19.4728L34.0888 12.3908C33.9967 12.0447 33.6787 11.8032 33.3149 11.8032H27.1999C26.9755 11.8032 26.7614 11.896 26.6099 12.0587C26.4583 12.2215 26.3833 12.4395 26.403 12.6593L27.0386 19.7413ZM28.568 18.8852L28.0736 13.377H32.6974L34.1635 18.8852H28.568Z"
				fill="#0072EF"
			/>
			<path
				d="M42.4001 19.6719H35.4056L33.6001 12.5899H39.4075L42.4001 19.6719Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M34.6293 19.8635C34.7186 20.2135 35.0384 20.459 35.4053 20.459H42.3998C42.6672 20.459 42.9169 20.3276 43.0653 20.1089C43.2137 19.8901 43.2412 19.6129 43.1385 19.3701L40.146 12.2881C40.0219 11.9946 39.7304 11.8032 39.4072 11.8032H33.5998C33.3534 11.8032 33.1207 11.915 32.9691 12.1061C32.8175 12.2972 32.7639 12.5464 32.8238 12.7815L34.6293 19.8635ZM36.0295 18.8852L34.6252 13.377H38.8734L41.201 18.8852H36.0295Z"
				fill="#0072EF"
			/>
			<path
				d="M9.22146 24.3936H9.17826C7.2021 24.3936 5.6001 22.9052 5.6001 21.0688V19.6722H12.8001V21.0688C12.8001 22.9052 11.1981 24.3936 9.22146 24.3936Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.7998 21.0686C4.7998 23.3812 6.80354 25.1802 9.17796 25.1802H9.22117C11.596 25.1802 13.5998 23.3812 13.5998 21.0686V19.672C13.5998 19.2374 13.2416 18.8851 12.7998 18.8851H5.5998C5.15798 18.8851 4.7998 19.2374 4.7998 19.672V21.0686ZM9.17796 23.6064C7.60008 23.6064 6.3998 22.4287 6.3998 21.0686V20.4589H11.9998V21.0686C11.9998 22.4287 10.7996 23.6064 9.22117 23.6064H9.17796Z"
				fill="#0072EF"
			/>
			<path
				d="M16.4212 24.3936H16.378C14.4018 24.3936 12.7998 22.9052 12.7998 21.0688V19.6722H19.9998V21.0688C19.9998 22.9052 18.3978 24.3936 16.4212 24.3936Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 21.0686C12 23.3812 14.0037 25.1802 16.3782 25.1802H16.4214C18.7962 25.1802 20.8 23.3812 20.8 21.0686V19.672C20.8 19.2374 20.4418 18.8851 20 18.8851H12.8C12.3582 18.8851 12 19.2374 12 19.672V21.0686ZM16.3782 23.6064C14.8003 23.6064 13.6 22.4287 13.6 21.0686V20.4589H19.2V21.0686C19.2 22.4287 17.9998 23.6064 16.4214 23.6064H16.3782Z"
				fill="#0072EF"
			/>
			<path
				d="M23.6214 24.3936H23.5782C21.602 24.3936 20 22.9052 20 21.0688V19.6722H27.2V21.0688C27.2 22.9052 25.598 24.3936 23.6214 24.3936Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.2002 21.0686C19.2002 23.3812 21.2039 25.1802 23.5784 25.1802H23.6216C25.9964 25.1802 28.0002 23.3812 28.0002 21.0686V19.672C28.0002 19.2374 27.642 18.8851 27.2002 18.8851H20.0002C19.5584 18.8851 19.2002 19.2374 19.2002 19.672V21.0686ZM23.5784 23.6064C22.0005 23.6064 20.8002 22.4287 20.8002 21.0686V20.4589H26.4002V21.0686C26.4002 22.4287 25.2 23.6064 23.6216 23.6064H23.5784Z"
				fill="#0072EF"
			/>
			<path
				d="M31.2239 24.3936H31.1759C28.9802 24.3936 27.2002 22.9052 27.2002 21.0688V19.6722H35.2002V21.0688C35.2002 22.9052 33.4202 24.3936 31.2239 24.3936Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M26.3999 21.0686C26.3999 23.4545 28.6648 25.1802 31.1756 25.1802H31.2236C33.735 25.1802 35.9999 23.4545 35.9999 21.0686V19.672C35.9999 19.2374 35.6417 18.8851 35.1999 18.8851H27.1999C26.7581 18.8851 26.3999 19.2374 26.3999 19.672V21.0686ZM31.1756 23.6064C29.295 23.6064 27.9999 22.3554 27.9999 21.0686V20.4589H34.3999V21.0686C34.3999 22.3554 33.1048 23.6064 31.2236 23.6064H31.1756Z"
				fill="#0072EF"
			/>
			<path
				d="M38.8216 24.3936H38.7784C36.8022 24.3936 35.2002 22.9052 35.2002 21.0688V19.6722H42.4002V21.0688C42.4002 22.9052 40.7982 24.3936 38.8216 24.3936Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M34.3999 21.0686C34.3999 23.3812 36.4036 25.1802 38.7781 25.1802H38.8213C41.1961 25.1802 43.1999 23.3812 43.1999 21.0686V19.672C43.1999 19.2374 42.8417 18.8851 42.3999 18.8851H35.1999C34.7581 18.8851 34.3999 19.2374 34.3999 19.672V21.0686ZM38.7781 23.6064C37.2002 23.6064 35.9999 22.4287 35.9999 21.0686V20.4589H41.5999V21.0686C41.5999 22.4287 40.3997 23.6064 38.8213 23.6064H38.7781Z"
				fill="#0072EF"
			/>
			<path
				d="M9.22146 24.3936H9.17826C7.2021 24.3936 5.6001 22.9052 5.6001 21.0688V19.6722H12.8001V21.0688C12.8001 22.9052 11.1981 24.3936 9.22146 24.3936Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.7998 21.0686C4.7998 23.3812 6.80354 25.1802 9.17796 25.1802H9.22117C11.596 25.1802 13.5998 23.3812 13.5998 21.0686V19.672C13.5998 19.2374 13.2416 18.8851 12.7998 18.8851H5.5998C5.15798 18.8851 4.7998 19.2374 4.7998 19.672V21.0686ZM9.17796 23.6064C7.60008 23.6064 6.3998 22.4287 6.3998 21.0686V20.4589H11.9998V21.0686C11.9998 22.4287 10.7996 23.6064 9.22117 23.6064H9.17796Z"
				fill="#0072EF"
			/>
			<path
				d="M16.4212 24.3936H16.378C14.4018 24.3936 12.7998 22.9052 12.7998 21.0688V19.6722H19.9998V21.0688C19.9998 22.9052 18.3978 24.3936 16.4212 24.3936Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 21.0686C12 23.3812 14.0037 25.1802 16.3782 25.1802H16.4214C18.7962 25.1802 20.8 23.3812 20.8 21.0686V19.672C20.8 19.2374 20.4418 18.8851 20 18.8851H12.8C12.3582 18.8851 12 19.2374 12 19.672V21.0686ZM16.3782 23.6064C14.8003 23.6064 13.6 22.4287 13.6 21.0686V20.4589H19.2V21.0686C19.2 22.4287 17.9998 23.6064 16.4214 23.6064H16.3782Z"
				fill="#0072EF"
			/>
			<path
				d="M23.6214 24.3936H23.5782C21.602 24.3936 20 22.9052 20 21.0688V19.6722H27.2V21.0688C27.2 22.9052 25.598 24.3936 23.6214 24.3936Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.2002 21.0686C19.2002 23.3812 21.2039 25.1802 23.5784 25.1802H23.6216C25.9964 25.1802 28.0002 23.3812 28.0002 21.0686V19.672C28.0002 19.2374 27.642 18.8851 27.2002 18.8851H20.0002C19.5584 18.8851 19.2002 19.2374 19.2002 19.672V21.0686ZM23.5784 23.6064C22.0005 23.6064 20.8002 22.4287 20.8002 21.0686V20.4589H26.4002V21.0686C26.4002 22.4287 25.2 23.6064 23.6216 23.6064H23.5784Z"
				fill="#0072EF"
			/>
			<path
				d="M31.2239 24.3936H31.1759C28.9802 24.3936 27.2002 22.9052 27.2002 21.0688V19.6722H35.2002V21.0688C35.2002 22.9052 33.4202 24.3936 31.2239 24.3936Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M26.3999 21.0686C26.3999 23.4545 28.6648 25.1802 31.1756 25.1802H31.2236C33.735 25.1802 35.9999 23.4545 35.9999 21.0686V19.672C35.9999 19.2374 35.6417 18.8851 35.1999 18.8851H27.1999C26.7581 18.8851 26.3999 19.2374 26.3999 19.672V21.0686ZM31.1756 23.6064C29.295 23.6064 27.9999 22.3554 27.9999 21.0686V20.4589H34.3999V21.0686C34.3999 22.3554 33.1048 23.6064 31.2236 23.6064H31.1756Z"
				fill="#0072EF"
			/>
			<path
				d="M38.8216 24.3936H38.7784C36.8022 24.3936 35.2002 22.9052 35.2002 21.0688V19.6722H42.4002V21.0688C42.4002 22.9052 40.7982 24.3936 38.8216 24.3936Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M34.3999 21.0686C34.3999 23.3812 36.4036 25.1802 38.7781 25.1802H38.8213C41.1961 25.1802 43.1999 23.3812 43.1999 21.0686V19.672C43.1999 19.2374 42.8417 18.8851 42.3999 18.8851H35.1999C34.7581 18.8851 34.3999 19.2374 34.3999 19.672V21.0686ZM38.7781 23.6064C37.2002 23.6064 35.9999 22.4287 35.9999 21.0686V20.4589H41.5999V21.0686C41.5999 22.4287 40.3997 23.6064 38.8213 23.6064H38.7781Z"
				fill="#0072EF"
			/>
			<path d="M18.3999 33.8358H10.3999V47.2129H18.3999V33.8358Z" fill="white" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.6001 47.2131C9.6001 47.6477 9.95827 48 10.4001 48H18.4001C18.8419 48 19.2001 47.6477 19.2001 47.2131V33.8361C19.2001 33.4015 18.8419 33.0492 18.4001 33.0492H10.4001C9.95827 33.0492 9.6001 33.4015 9.6001 33.8361V47.2131ZM11.2001 46.4262V34.623H17.6001V46.4262H11.2001Z"
				fill="#0072EF"
			/>
			<path d="M37.5998 42.4917H20.7998V33.836H37.5998V42.4917Z" fill="white" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.7998 42.4919C20.7998 42.9265 21.1424 43.2788 21.565 43.2788H37.6346C38.0572 43.2788 38.3998 42.9265 38.3998 42.4919V33.8362C38.3998 33.4016 38.0572 33.0493 37.6346 33.0493H21.565C21.1424 33.0493 20.7998 33.4016 20.7998 33.8362V42.4919ZM22.3302 41.705V34.6231H36.8694V41.705H22.3302Z"
				fill="#0072EF"
			/>
			<path d="M28.7998 38.5576L32.7998 35.4101L28.7998 38.5576Z" fill="white" />
			<path
				d="M21.9002 4.47825C21.6419 2.3998 19.7808 0.787054 17.5192 0.787054C15.2581 0.787054 13.397 2.3998 13.1387 4.47825H8.7998V12.5903H39.1998V4.47825H21.9002Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 12.5901C8 13.0247 8.35817 13.377 8.8 13.377H39.2C39.6418 13.377 40 13.0247 40 12.5901V4.47799C40 4.0434 39.6418 3.6911 39.2 3.6911H22.5562C21.9605 1.55055 19.9167 -9.63211e-05 17.5194 -9.63211e-05C15.1225 -9.63211e-05 13.0788 1.55058 12.4831 3.6911H8.8C8.35817 3.6911 8 4.0434 8 4.47799V12.5901ZM9.6 11.8032V5.26487H13.1389C13.5432 5.26487 13.8839 4.96819 13.933 4.57347C14.1401 2.90639 15.6453 1.57367 17.5194 1.57367C19.394 1.57367 20.8991 2.90644 21.1063 4.57347C21.1554 4.96819 21.4961 5.26487 21.9004 5.26487H38.4V11.8032H9.6Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.8835 40.1105C29.1995 39.7996 29.1995 39.2901 28.8835 38.9793L26.3269 36.4646C26.0181 36.1609 25.5229 36.1609 25.2142 36.4646C24.8982 36.7754 24.8982 37.2849 25.2142 37.5958L27.7708 40.1105C28.0796 40.4142 28.5748 40.4142 28.8835 40.1105Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M30.2253 38.1325C30.5414 37.8216 30.5414 37.3121 30.2253 37.0013L29.6799 36.4648C29.3712 36.1611 28.876 36.1611 28.5672 36.4648C28.2512 36.7756 28.2512 37.2851 28.5672 37.596L29.1127 38.1325C29.4214 38.4361 29.9166 38.4361 30.2253 38.1325Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M45.5993 47.1999C45.5993 47.6417 45.2411 47.9999 44.7993 47.9999H3.20088C2.75905 47.9999 2.40088 47.6417 2.40088 47.1999C2.40088 46.7581 2.75905 46.3999 3.20088 46.3999H44.7993C45.2411 46.3999 45.5993 46.7581 45.5993 47.1999Z"
				fill="#0072EF"
			/>
		</SVG>
	);
}
