import { Wrapper } from "./index.styles";
import Icon, { IconsType } from "../icon";

export type Props = {
	icon: IconsType;
	size?: string;
};

export default function IconCircle({ icon, size }: Props) {
	return (
		<Wrapper size={size} className="icon-circle">
			<Icon as={icon} color="primary" />
		</Wrapper>
	);
}
