import SVG, { Props } from "utils/svg";

export default function Linkedin({ color, width, height }: Props) {
	return (
		<SVG
			viewBox="0 0 28 28"
			aria-labelledby="Linkedin"
			color={color}
			height={height}
			width={width}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.5718 9.95899H15.5238V12.4257C16.2372 11.007 18.0665 9.73233 20.8145 9.73233C26.0825 9.73233 27.3332 12.5563 27.3332 17.7377V27.3337H21.9998V18.9177C21.9998 15.967 21.2865 14.303 19.4705 14.303C16.9518 14.303 15.9052 16.0963 15.9052 18.9163V27.3337H10.5718V9.95899ZM1.4265 27.107H6.75984V9.73233H1.4265V27.107ZM7.52384 4.06699C7.52403 4.51403 7.43538 4.95664 7.26302 5.36911C7.09066 5.78158 6.83804 6.15568 6.51984 6.46966C5.87504 7.11049 5.00225 7.4692 4.09317 7.46699C3.18569 7.46638 2.31492 7.10859 1.66917 6.47099C1.35212 6.15594 1.10035 5.78142 0.928295 5.3689C0.756239 4.95638 0.667276 4.51396 0.666504 4.06699C0.666504 3.16433 1.0265 2.30033 1.6705 1.66299C2.31568 1.02454 3.18682 0.666593 4.0945 0.666993C5.00384 0.666993 5.87584 1.02566 6.51984 1.66299C7.1625 2.30033 7.52384 3.16433 7.52384 4.06699Z"
			/>
		</SVG>
	);
}
