import { H1, H2, H3, H4, H5, H6 } from "./index.styles";

type Props = {
	as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
	children: React.ReactNode;
};

export default function Heading({ as = "h1", children }: Props) {
	switch (as) {
		case "h2":
			return <H2 className="heading h2">{children}</H2>;
		case "h3":
			return <H3 className="heading h3">{children}</H3>;
		case "h4":
			return <H4 className="heading h4">{children}</H4>;
		case "h5":
			return <H5 className="heading h5">{children}</H5>;
		case "h6":
			return <H6 className="heading h6">{children}</H6>;
		case "h1":
		default:
			return <H1 className="heading h1">{children}</H1>;
	}
}
