import styled from "styled-components";
import { Page as _Page } from "index.styles";

import { Devices } from "constants";
import Img1 from "./img-1.svg";
import Img1M from "./img-1-m.svg";
import Img1T from "./img-1-t.svg";

const { tablet, mobile } = Devices;

export const Wrapper = styled.div`
	margin: 0 auto;
	display: flex;
	flex-direction: column;
`;

export const Page = styled(_Page)`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	background-image: url(${Img1});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 35px;
	@media screen and (max-width: ${mobile}) {
		padding: 25px;
		background-image: url(${Img1M});
	}
	@media screen and (max-width: ${tablet}) {
		background-image: url(${Img1T});
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 227px;
	@media screen and (max-width: ${mobile}) {
		margin-top: 175px;
	}
`;

export const LogoWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-right: 37px;
	@media screen and (max-width: ${mobile}) {
		padding-right: 0;
	}
`;

export const Container = styled.div`
	max-width: 424px;
	width: 100%;
	margin: 0 auto;
	color: var(--primary);
	p.xxl {
		margin-top: 12px;
	}
	p.xl {
		margin-top: 0px;
		margin-bottom: 0px;
	}
	p.l {
		margin-top: 84px;
		margin-bottom: 0;
	}
`;
