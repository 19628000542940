import { useNavigate } from "react-router-dom";

import Button from "components/elements/button";
import Logo from "components/elements/logo";
import Text from "components/elements/text";
import Icon from "components/elements/icon";
import Footer from "components/sections/footer";

import {
	Page,
	ButtonWrapper,
	Container,
	LogoWrapper,
	Wrapper,
} from "./index.styles";

export default function Welcome() {
	const navigate = useNavigate();

	return (
		<Page>
			<Container>
				<Wrapper>
					<div>
						<LogoWrapper>
							<Logo />
						</LogoWrapper>
						<Text size="xxl">Be The Boss</Text>
					</div>
					<Text size="l">
						Help decide how Ausgrid should plan for the future of the electricity
						network.
					</Text>
					<div>
						<ButtonWrapper>
							<Button
								variant="primary"
								onClick={() => navigate("/how-to-play")}
								size="m"
							>
								Let&#39;s go <Icon size="s" as="arrow-right" color="white" />
							</Button>
						</ButtonWrapper>
						<Footer />
					</div>
				</Wrapper>
			</Container>
		</Page>
	);
}
