import styled from "styled-components";
import { Devices } from "constants";

const { mobile } = Devices;

export const Wrapper = styled.div`
	align-items: center;
	position: relative;
	padding: 16px;
	background: var(--white);
	background-clip: padding-box;
	border: solid 4px transparent;
	border-radius: 12px;
	box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	@media (hover: hover) {
		&:hover {
			box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15),
				0px 4px 4px rgba(0, 0, 0, 0.3),
				inset 0px 185px 0px rgba(255, 255, 255, 0.25);
		}
	}
	&:active {
		box-shadow: inset 0px 185px 4px rgba(0, 0, 0, 0.1);
	}
	@media screen and (max-width: ${mobile}) {
		height: 100%;
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		margin: -4px;
		border-radius: inherit;
		background: linear-gradient(90deg, #72bf44 0.58%, #0095d5 100%);
	}

	> p {
		margin: 8px 0 0;
		&.m {
			font-weight: 500;
			display: flex;
			flex-grow: 1;
			align-items: flex-end;
		}
	}
`;

export const HeadingWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	img {
		width: 48px;
	}
	.heading {
		margin: 0 0 0 10px;
	}
`;
