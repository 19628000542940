import Logo from "components/elements/logo";
import Text from "components/elements/text";

import { Wrapper } from "./index.styles";

export default function Footer() {
	return (
		<Wrapper>
			<div>
				<Text>
					<strong>Be The Boss is powered by</strong>
				</Text>
				<Logo size="s" color="white" />
			</div>
			<div>
				<Text>
					<a
						href="https://www.ausgrid.com.au/privacy"
						rel="noreferrer"
						target="_blank"
					>
						<strong>Privacy Policy</strong>
					</a>
				</Text>
			</div>
		</Wrapper>
	);
}
