import SVG, { Props } from "utils/svg";

export default function ArrowLeft({ color, width, height }: Props) {
	return (
		<SVG
			viewBox="0 0 1000 1000"
			aria-labelledby="Arrow Left"
			color={color}
			height={height}
			width={width}
		>
			<path d="M990,533.4H137.5l409.5,409.5L500,990L10,500L500,10l47.1,47.1L137.5,466.7H990V533.4z" />
		</SVG>
	);
}
