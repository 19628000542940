import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 25px;
	padding: 12px;
	margin-top: 33px;
	.item {
		width: 31.43%;
	}
`;
