import { getColor } from "utils/helper";
import SVG, { Props } from "utils/svg";

export default function GlobeMeter({ color, width, height }: Props) {
	return (
		<SVG
			viewBox="0 0 48 49"
			aria-labelledby="Globe Meter"
			color={color}
			height={height}
			width={width}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.6903 43.651C23.6257 44.2295 24.0424 44.7535 24.6234 44.7909C25.1052 44.822 25.5913 44.8379 26.081 44.8379C38.462 44.8379 48.4989 34.8009 48.4989 22.4189C48.4989 10.037 38.462 -2.28882e-05 26.081 -2.28882e-05C24.5031 -2.28882e-05 22.9628 0.1631 21.4758 0.473672C20.9059 0.592693 20.5676 1.17039 20.7135 1.734V1.734C20.8593 2.29761 21.434 2.63343 22.0045 2.51723C23.3206 2.24912 24.6836 2.10821 26.081 2.10821C37.2976 2.10821 46.3907 11.2013 46.3907 22.4189C46.3907 33.6366 37.2976 42.7297 26.081 42.7297C25.6701 42.7297 25.262 42.7173 24.8573 42.6932C24.2762 42.6585 23.7548 43.0724 23.6903 43.651V43.651Z"
				fill="#2D64B0"
			/>
			<path
				d="M43.7567 23.7176H47.4081C47.9352 15.2847 43.7187 8.4329 38.4481 5.27055L36.3779 7.90585H31.1073L28.999 10.0141H26.8908C24.5623 10.0141 23.1635 11.902 23.1635 14.2305V16.3388H30.0532L35.3237 21.6094H38.4861L39.5402 20.5552H41.6484L43.7567 23.7176Z"
				fill="#69C52F"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M42.8796 24.3023C43.0751 24.5955 43.4042 24.7717 43.7567 24.7717H47.4081C47.9648 24.7717 48.4255 24.3389 48.4602 23.7833C49.0114 14.9636 44.6041 7.73479 38.9905 4.36661C38.535 4.09333 37.9473 4.20162 37.6192 4.61931L35.8655 6.85169H31.1073C30.8277 6.85169 30.5596 6.96275 30.3619 7.16043L28.5624 8.95992H26.8908C25.4161 8.95992 24.1884 9.56886 23.3467 10.5712C22.52 11.5556 22.1093 12.8628 22.1093 14.2305V16.3387C22.1093 16.9209 22.5813 17.3928 23.1634 17.3928H29.6165L34.5783 22.3547C34.776 22.5524 35.0442 22.6634 35.3237 22.6634H38.4861C38.7656 22.6634 39.0338 22.5524 39.2314 22.3547L39.9768 21.6093H41.0843L42.8796 24.3023ZM44.3208 22.6634L42.5255 19.9705C42.33 19.6772 42.0009 19.5011 41.6484 19.5011H39.5402C39.2606 19.5011 38.9925 19.6121 38.7948 19.8098L38.0494 20.5552H35.7604L30.7985 15.5934C30.6008 15.3957 30.3327 15.2846 30.0531 15.2846H24.2175V14.2305C24.2175 13.2697 24.5063 12.4686 24.9611 11.9269C25.401 11.4032 26.037 11.0681 26.8908 11.0681H28.999C29.2786 11.0681 29.5467 10.9571 29.7444 10.7594L31.5439 8.95992H36.3778C36.7013 8.95992 37.0069 8.81139 37.2068 8.557L38.6844 6.6761C43.05 9.68733 46.5178 15.4982 46.3954 22.6634H44.3208Z"
				fill="#2D64B0"
			/>
			<path
				d="M31.6344 36.4047H28.9611V29.0259L26.3638 26.9177H22.6364V23.7553L25.3097 20.593H30.5802L36.3399 25.8636V32.1883L31.6344 36.4047Z"
				fill="#69C52F"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.907 36.4047C27.907 36.9869 28.3789 37.4589 28.9611 37.4589H31.6343C31.894 37.4589 32.1444 37.3631 32.3378 37.1898L37.0434 32.9733C37.2665 32.7734 37.394 32.4879 37.394 32.1883V25.8636C37.394 25.5678 37.2697 25.2856 37.0515 25.0859L31.2918 19.8153C31.0975 19.6375 30.8436 19.5389 30.5802 19.5389H25.3096C24.9994 19.5389 24.7049 19.6755 24.5046 19.9125L21.8314 23.0748C21.6705 23.2651 21.5823 23.5062 21.5823 23.7553V26.9177C21.5823 27.4999 22.0542 27.9718 22.6364 27.9718H25.9898L27.907 29.528V36.4047ZM30.0152 35.3506V29.0259C30.0152 28.7083 29.872 28.4076 29.6254 28.2075L27.0281 26.0993C26.8402 25.9468 26.6057 25.8636 26.3637 25.8636H23.6905V24.1412L25.7988 21.6471H30.1707L35.2858 26.3278V31.7174L31.2311 35.3506H30.0152Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.499939 7.94356C0.499939 8.52574 0.971883 8.99768 1.55406 8.99768H3.66229C4.24446 8.99768 4.71641 8.52574 4.71641 7.94356V7.94356C4.71641 7.36139 4.24446 6.88945 3.66229 6.88945H1.55406C0.971883 6.88945 0.499939 7.36139 0.499939 7.94356V7.94356Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.499939 14.2683C0.499939 14.8504 0.971883 15.3224 1.55406 15.3224H3.66229C4.24446 15.3224 4.71641 14.8504 4.71641 14.2683V14.2683C4.71641 13.6861 4.24446 13.2142 3.66229 13.2142H1.55406C0.971883 13.2142 0.499939 13.6861 0.499939 14.2683V14.2683Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.499939 20.593C0.499939 21.1752 0.971883 21.6471 1.55406 21.6471H3.66229C4.24446 21.6471 4.71641 21.1752 4.71641 20.593V20.593C4.71641 20.0108 4.24446 19.5389 3.66229 19.5389H1.55406C0.971883 19.5389 0.499939 20.0108 0.499939 20.593V20.593Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.499939 26.9176C0.499939 27.4998 0.971883 27.9717 1.55406 27.9717H3.66229C4.24446 27.9717 4.71641 27.4998 4.71641 26.9176V26.9176C4.71641 26.3355 4.24446 25.8635 3.66229 25.8635H1.55406C0.971883 25.8635 0.499939 26.3355 0.499939 26.9176V26.9176Z"
				fill="#2D64B0"
			/>
			<path
				d="M17.3658 33.5164V6.88943C17.3658 4.56088 15.4779 2.67296 13.1493 2.67296C10.8208 2.67296 8.93286 4.56088 8.93286 6.88943V33.5164C7.0228 34.8499 5.77051 37.0604 5.77051 39.567C5.77051 43.6423 9.07411 46.9459 13.1493 46.9459C17.2245 46.9459 20.5281 43.6423 20.5281 39.567C20.5281 37.0604 19.2759 34.8499 17.3658 33.5164Z"
				fill="#69C52F"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.1494 45.8918C9.65632 45.8918 6.82466 43.0601 6.82466 39.5671C6.82466 37.4207 7.8954 35.5263 9.5363 34.3808L9.98701 34.0661V6.88944C9.98701 5.14307 11.403 3.72709 13.1494 3.72709C14.8957 3.72709 16.3117 5.14307 16.3117 6.88944V34.0661L16.7624 34.3808C18.4033 35.5263 19.4741 37.4207 19.4741 39.5671C19.4741 43.0601 16.6424 45.8918 13.1494 45.8918ZM4.71643 39.5671C4.71643 44.2245 8.49198 48 13.1494 48C17.8068 48 21.5823 44.2245 21.5823 39.5671C21.5823 36.9027 20.344 34.5324 18.4199 32.9896V6.88944C18.4199 3.97873 16.0601 1.61886 13.1494 1.61886C10.2386 1.61886 7.87878 3.97873 7.87878 6.88944V32.9896C5.95473 34.5324 4.71643 36.9027 4.71643 39.5671Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.1493 40.6212C13.7315 40.6212 14.2034 40.1492 14.2034 39.567V11.1059C14.2034 10.5237 13.7315 10.0518 13.1493 10.0518V10.0518C12.5672 10.0518 12.0952 10.5237 12.0952 11.1059V39.567C12.0952 40.1492 12.5672 40.6212 13.1493 40.6212V40.6212Z"
				fill="#2D64B0"
			/>
		</SVG>
	);
}
