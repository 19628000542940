import { useParams } from "react-router-dom";

import Base from "components/templates/base";
import CardPrimary from "components/sections/card-primary";
import AdvisersData from "data/advisers";

import { Wrapper } from "./index.styles";

export default function Adviser() {
	const { id } = useParams();
	const getAdviser = AdvisersData.filter((item) => item.id === id)[0];

	const { icon, text, headIcon } = getAdviser;
	return (
		<Base title="Back to question" back="back to question" icon={headIcon}>
			<Wrapper>
				<CardPrimary
					content={text}
					icon={icon}
					title="What the adviser says"
					back="back to question"
				/>
			</Wrapper>
		</Base>
	);
}
