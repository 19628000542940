import { textSize } from "components/elements/text/index.styles";
import styled, { createGlobalStyle } from "styled-components";
import { colors, fonts } from "./constants";

const colorVars = Object.entries(colors).map(
	([name, value]) => `--${name}: ${value};`
);
const fontVars = Object.entries(fonts).map(
	([name, value]) => `--${name}: ${value};`
);

export const GlobalStyle = createGlobalStyle`
  :root{
    ${colorVars}
    ${fontVars}
    --boxShadowBlue: 0px 2px 4px rgba(58, 92, 144, 0.14), 0px 3px 4px rgba(58, 92, 144, 0.12), 0px 1px 5px rgba(58, 92, 144, 0.2);
    --boxShadow: 0px 3px 14.25px rgba(0, 0, 0, 0.2);
  }
  body{
    margin: 0;
    padding: 0;
  }
  body, input, select{
    color: var(--paragraph);
    font-family: var(--primaryFont);
    line-height: 21px;
    font-weight: 400;
    font-size: ${textSize.default}px;
  }
  img{
    max-width: 100%;
    width: 100%;
  }
  *{
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }
  html, body, #root {
    display: block;
  }
  strong{
    font-weight: 600;
  }
  b{
    font-weight: 500;
  }
  a{
    color: var(--primary);
    @media (hover: hover) {
    &:hover{
      text-decoration: none;
    }
  }
    &:active{
      text-decoration: underline;
    }
  }

  
  ul{
    &.unset{
      list-style-type: none;
      display: flex;
      margin: 0;
      padding: 0
    }
  }
`;

const Width = 1440;

export const Container = styled.div`
	max-width: ${Width}px;
	width: 100%;
	margin: 0 auto;
`;

export const SmlContainer = styled.div`
	max-width: 370px;
	width: 100%;
	padding: 20px;
`;

export const Page = styled.div`
	min-height: 100vh;
	height: 100%;
`;

export default GlobalStyle;
