import styled, { css } from "styled-components";
import { Devices } from "constants";

const { tablet, mobile } = Devices;

export const Wrapper = styled.div`
	position: relative;
	padding: 16px;
	background: var(--white);
	background-clip: padding-box;
	border: solid 4px transparent;
	border-radius: 12px;
	box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
	white-space: break-spaces;
	display: flex;
	flex-direction: column;
	height: 100%;
	/* align-items: center; */ // adviser screen
	justify-content: center;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		margin: -4px;
		border-radius: inherit;
		background: linear-gradient(90deg, #72bf44 0.58%, #0095d5 100%);
	}

	p {
		margin-bottom: 0;
		font-size: 18px;
		line-height: 27px;
		letter-spacing: -0.01em;
		&:nth-of-type(1) {
			margin-top: 0;
		}
		@media screen and (max-width: ${mobile}) {
			font-size: 14px;
			line-height: 21px;
		}
	}
	strong {
		font-weight: 700;
	}
	${({ indent }) =>
		indent &&
		css`
			.heading {
				padding-left: 62px;
				@media screen and (max-width: ${mobile}) {
					padding-left: 70px;
				}
			}
		`}
	.heading {
		margin-top: 0;
	}
	.icon-circle {
		position: absolute;
		top: -39px;
		left: -16px;
		@media screen and (max-width: ${mobile}) {
			top: -38px;
		}
	}
	button {
		margin-top: 15px;
		padding-top: 10px;
	}
`;
