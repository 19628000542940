import styled from "styled-components";
import { Devices } from "constants";

const { tablet, mobile } = Devices;

export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 30px;
	margin-top: 25px;
	text-align: ${({ align }) => align || "left"};
	@media screen and (max-width: ${tablet}) {
		flex-direction: column;
	}
	@media screen and (max-width: ${mobile}) {
		gap: 16px;
	}
	p {
		margin: 0;
	}
	> div {
		display: flex;
		flex-direction: column;
		flex-basis: 100%;
		flex: 1;
		min-height: 144px;
	}
`;

export const RightWrapper = styled.div`
	display: flex;
	flex-direction: column;
	> p {
		text-align: justify;
	}
	> div {
		display: flex;
		justify-content: center;
		align-items: center;
		@media screen and (max-width: ${mobile}) {
			flex-direction: column;
			text-align: center;
			&:nth-child(1) {
				p {
					&.xl {
						font-size: 24px;
						line-height: 29px;
					}
				}
			}
		}
		& > p:nth-of-type(2) {
			padding: 0 32px;
		}
		& + p {
			text-align: center;
			margin-top: 12px;
			@media screen and (max-width: ${mobile}) {
				font-size: 14px;
				line-height: 19px;
			}
		}
	}
	p {
		margin: 0;
	}
`;
