const Advisers = [
	{
		id: "1",
		headIcon: "bulb",
		icon: "he-tash",
		text: (
			<p>
				Innovation can deliver lower bills over time, but would increase bills in
				the short term. With more innovation funding you could invest in more
				community based trials or become more efficient. But some money may be
				wasted – not every project will be successful.
			</p>
		),
	},
	{
		id: "2",
		headIcon: "chat",
		icon: "he-mic",
		text: (
			<>
				<p>
					Some customers want information in different languages, some want Chatbots
					and others want to talk to a real person.{" "}
				</p>
				<p>
					Some business customers or even local councils want to use the website as a
					way to put in orders and cut down on paper.{" "}
				</p>
				<p>
					With nearly two million customers and stakeholders, good customer service
					is different for different people. Should services stay the same and keep
					prices down, or should we invest more?
				</p>
			</>
		),
	},
	{
		id: "3",
		headIcon: "globe-meter",
		icon: "she-curly",
		text: (
			<>
				<p>
					Climate scientists can predict the impacts of extreme weather with a degree
					of certainty, but you can’t be 100% sure.
				</p>
				<p>
					If you invest in the wrong place at the wrong time that might be wasted
					money that’ll push prices up unnecessarily.{" "}
				</p>
				<p>
					With no investment you can expect some customers to have more frequent and
					longer outages as we get more extreme weather.
				</p>
			</>
		),
	},
	{
		id: "4",
		headIcon: "security",
		icon: "he-shave",
		text: (
			<>
				<p>
					Your advisor tells you there are two main types of cyber attacks. The
					attackers might be trying to access data or they might be trying to cause
					disruption by causing a power outage.{" "}
				</p>
				<p>
					Do you do enough to comply with the governments’ minimum standards, keeping
					costs as low as possible, or do you want to have advanced cyber security?
				</p>
			</>
		),
	},
	{
		id: "5",
		headIcon: "solar-sun",
		icon: "he-cap",
		text: (
			<>
				<p>
					Customers tell you they want to add more solar panels, put in electric
					vehicle charging stations and make their own investment in a net zero
					future.
				</p>
				<p>Would you prefer to:</p>
				<p>
					<strong>A:</strong> invest in the network to support customers to use or
					export electricity whenever they want to.{" "}
				</p>
				<p>
					<strong>B:</strong> keep costs low, but some customers won’t be able to add
					solar or charge electric vehicles because of a lack of network capacity.
				</p>
			</>
		),
	},
	{
		id: "6",
		headIcon: "dollar-tag",
		icon: "he-beard",
		text: (
			<>
				<p>
					What’s the fairest way to change how you charge or reward people for
					exporting electricity?
				</p>
				<p>
					You can introduce the charges now, and then everyone pays the same – but
					customers that have already invested in solar panels weren’t expecting to
					be charged, so is that fair?{" "}
				</p>
				<p>
					You could slowly introduce the charges, starting with new solar customers
					and gradually rolling out new prices to everyone.
				</p>
			</>
		),
	},
];
export default Advisers;
