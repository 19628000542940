import SVG, { Props } from "utils/svg";

export default function DollarTag({ color, width, height }: Props) {
	return (
		<SVG
			viewBox="0 0 48 48"
			aria-labelledby="Dollar Tag"
			color={color}
			height={height}
			width={width}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M29.4592 5.76001H27.16H21.4H19.1008C18.1293 5.76001 17.2125 6.21121 16.6202 6.98113L8.60895 17.3962C8.18847 17.9434 7.95999 18.6144 7.95999 19.3046V43.9104C7.95999 45.6384 9.36159 47.04 11.0896 47.04H25.24H37.4704C39.1984 47.04 40.6 45.6384 40.6 43.9104V19.3046C40.6 18.6144 40.3715 17.9434 39.951 17.3962L31.9398 6.98113C31.3475 6.21121 30.4307 5.76001 29.4592 5.76001ZM24.28 14.4001C25.8707 14.4001 27.16 13.1108 27.16 11.5201C27.16 9.92933 25.8707 8.64005 24.28 8.64005C22.6893 8.64005 21.4 9.92933 21.4 11.5201C21.4 13.1108 22.6893 14.4001 24.28 14.4001Z"
				fill="#69C52F"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7 43.9104C7 46.1686 8.83141 48 11.0896 48H37.4704C39.7286 48 41.56 46.1686 41.56 43.9104V19.3046C41.56 18.4025 41.2614 17.5259 40.7123 16.8112L32.7008 6.39581C31.9266 5.38956 30.7286 4.8 29.4592 4.8H28.12C27.5898 4.8 27.16 5.22981 27.16 5.76V5.76C27.16 6.29019 27.5898 6.72 28.12 6.72H29.4592C30.1328 6.72 30.7685 7.0329 31.179 7.56649L39.1898 17.9811C39.4816 18.3609 39.64 18.8263 39.64 19.3046V43.9104C39.64 45.1082 38.6682 46.08 37.4704 46.08H11.0896C9.89179 46.08 8.92 45.1082 8.92 43.9104V19.3046C8.92 18.8263 9.07836 18.3609 9.37018 17.9811L17.381 7.56649C17.7915 7.0329 18.4272 6.72 19.1008 6.72H20.44C20.9702 6.72 21.4 6.29019 21.4 5.76V5.76C21.4 5.22981 20.9702 4.8 20.44 4.8H19.1008C17.8314 4.8 16.6334 5.38949 15.8593 6.39575L7.84803 16.8109C7.29888 17.5255 7 18.4025 7 19.3046V43.9104Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.44 11.52C20.44 13.6409 22.1591 15.36 24.28 15.36C26.4009 15.36 28.12 13.6409 28.12 11.52C28.12 9.39907 26.4009 7.67999 24.28 7.67999C22.1591 7.67999 20.44 9.39907 20.44 11.52ZM24.28 13.44C23.2195 13.44 22.36 12.5805 22.36 11.52C22.36 10.4595 23.2195 9.59999 24.28 9.59999C25.3405 9.59999 26.2 10.4595 26.2 11.52C26.2 12.5805 25.3405 13.44 24.28 13.44Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.209 1.43051e-05C23.6788 1.43051e-05 23.249 0.42982 23.249 0.960013V7.68002C23.249 8.21021 23.6788 8.64001 24.209 8.64001V8.64001C24.7392 8.64001 25.169 8.21021 25.169 7.68002V0.960014C25.169 0.429821 24.7392 1.43051e-05 24.209 1.43051e-05V1.43051e-05Z"
				fill="#2D64B0"
			/>
			<path
				d="M19.0348 38.6496C20.3158 39.2884 21.8153 39.6888 23.5326 39.8515L25.275 39.9024C26.9675 39.8515 28.3451 39.4406 29.4069 38.6698C30.4686 37.8979 31.0005 36.7325 31.0005 35.1725C31.0005 34.3258 30.8133 33.5962 30.4398 32.9818C30.0664 32.3683 29.5777 31.895 28.971 31.5629C28.3653 31.2317 27.6981 30.9408 26.9675 30.6922C26.2379 30.4435 25.5073 30.2237 24.7777 30.0326C24.0472 29.8426 23.379 29.6304 22.7742 29.3981C22.1675 29.1658 21.6789 28.8422 21.3045 28.4275C20.932 28.0128 20.7448 27.5059 20.7448 26.9088C20.7448 25.2163 22.0811 24.3696 24.7528 24.3696C26.1105 24.3476 27.3491 24.5603 28.4675 25.0082C29.1046 25.2633 29.8792 24.8334 29.8792 24.1471C29.8792 23.838 29.716 23.5492 29.4392 23.4116C28.2666 22.8287 26.8783 22.492 25.275 22.4035L23.5326 22.4285C22.0888 22.5946 20.9109 23.0678 19.9989 23.8474C19.0859 24.6278 18.6299 25.6723 18.6299 26.9837C18.6299 27.8131 18.8161 28.5274 19.1896 29.1245C19.563 29.7216 20.0526 30.1814 20.6584 30.5059C21.2632 30.8294 21.9313 31.1117 22.6619 31.3517C23.3925 31.5926 24.1221 31.8125 24.8517 32.0112C25.5822 32.2109 26.2494 32.4346 26.8561 32.6832C27.4609 32.9328 27.9515 33.2765 28.324 33.7171C28.6974 34.1568 28.8846 34.6915 28.8846 35.3213C28.8846 37.0474 27.4744 37.9104 24.6529 37.9104C22.9399 37.9104 21.383 37.6177 19.9815 37.0322C19.3447 36.7661 18.58 37.2022 18.58 37.8924C18.58 38.2109 18.7498 38.5075 19.0348 38.6496Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.209 23.86C24.7392 23.86 25.169 23.368 25.169 22.7612V19.8309C25.169 19.2241 24.7392 18.7321 24.209 18.7321C23.6788 18.7321 23.249 19.2241 23.249 19.8309V22.7612C23.249 23.368 23.6788 23.86 24.209 23.86Z"
				fill="#2D64B0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.209 42.7321C24.7392 42.7321 25.169 42.3484 25.169 41.875V39.5893C25.169 39.1159 24.7392 38.7321 24.209 38.7321C23.6788 38.7321 23.249 39.1159 23.249 39.5893V41.875C23.249 42.3484 23.6788 42.7321 24.209 42.7321Z"
				fill="#2D64B0"
			/>
		</SVG>
	);
}
