import { Paragraph } from "./index.styles";

type Props = {
	size?: "xxl" | "xl" | "l" | "m" | "s" | "xs" | "default";
	children: React.ReactNode;
};

export default function Text({ size = "default", children }: Props) {
	return (
		<Paragraph className={`text ${size}`} size={size}>
			{children}
		</Paragraph>
	);
}
