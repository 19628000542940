import { getColor } from "utils/helper";
import SVG, { Props } from "utils/svg";

export default function Skyscraper({ color, width, height }: Props) {
	return (
		<SVG
			viewBox="0 0 48 48"
			aria-labelledby="Skyscraper"
			color={color}
			height={height}
			width={width}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.9191 48C14.361 48 14.7191 47.6418 14.7191 47.2V4.1846H30.5748V47.2C30.5748 47.6418 30.933 48 31.3748 48C31.8166 48 32.1748 47.6418 32.1748 47.2V3.38461C32.1748 2.94278 31.8166 2.58461 31.3748 2.58461H13.9191C13.4773 2.58461 13.1191 2.94278 13.1191 3.38461V47.2C13.1191 47.6418 13.4773 48 13.9191 48Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.447 48C20.8888 48 21.247 47.6418 21.247 47.2V40.9861H24.0464V47.2C24.0464 47.6418 24.4046 48 24.8464 48C25.2882 48 25.6464 47.6418 25.6464 47.2V40.1861C25.6464 39.7443 25.2882 39.3861 24.8464 39.3861H20.447C20.0051 39.3861 19.647 39.7443 19.647 40.1861V47.2C19.647 47.6418 20.0051 48 20.447 48Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.17549 48C2.61732 48 2.97549 47.6418 2.97549 47.2V25.9003H13.9194C14.3612 25.9003 14.7194 25.5421 14.7194 25.1003C14.7194 24.6585 14.3612 24.3003 13.9194 24.3003H2.17549C1.73366 24.3003 1.37549 24.6585 1.37549 25.1003V47.2C1.37549 47.6418 1.73366 48 2.17549 48Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.93604 29.1961C4.93604 29.6379 5.29421 29.9961 5.73604 29.9961H5.91851C6.36034 29.9961 6.71851 29.6379 6.71851 29.1961C6.71851 28.7543 6.36034 28.3961 5.91851 28.3961H5.73604C5.29421 28.3961 4.93604 28.7543 4.93604 29.1961ZM9.37664 29.1961C9.37664 29.6379 9.73482 29.9961 10.1766 29.9961H10.3591C10.8009 29.9961 11.1591 29.6379 11.1591 29.1961C11.1591 28.7543 10.8009 28.3961 10.3591 28.3961H10.1766C9.73482 28.3961 9.37664 28.7543 9.37664 29.1961Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.93604 32.6043C4.93604 33.0461 5.29421 33.4043 5.73604 33.4043H5.91851C6.36034 33.4043 6.71851 33.0461 6.71851 32.6043C6.71851 32.1625 6.36034 31.8043 5.91851 31.8043H5.73604C5.29421 31.8043 4.93604 32.1625 4.93604 32.6043ZM9.37664 32.6043C9.37664 33.0461 9.73482 33.4043 10.1766 33.4043H10.3591C10.8009 33.4043 11.1591 33.0461 11.1591 32.6043C11.1591 32.1625 10.8009 31.8043 10.3591 31.8043H10.1766C9.73482 31.8043 9.37664 32.1625 9.37664 32.6043Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.93604 36.0125C4.93604 36.4543 5.29421 36.8125 5.73604 36.8125H5.91851C6.36034 36.8125 6.71851 36.4543 6.71851 36.0125C6.71851 35.5707 6.36034 35.2125 5.91851 35.2125H5.73604C5.29421 35.2125 4.93604 35.5707 4.93604 36.0125ZM9.37664 36.0125C9.37664 36.4543 9.73482 36.8125 10.1766 36.8125H10.3591C10.8009 36.8125 11.1591 36.4543 11.1591 36.0125C11.1591 35.5707 10.8009 35.2125 10.3591 35.2125H10.1766C9.73482 35.2125 9.37664 35.5707 9.37664 36.0125Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.93604 39.4207C4.93604 39.8625 5.29421 40.2207 5.73604 40.2207H5.91851C6.36034 40.2207 6.71851 39.8625 6.71851 39.4207C6.71851 38.9789 6.36034 38.6207 5.91851 38.6207H5.73604C5.29421 38.6207 4.93604 38.9789 4.93604 39.4207ZM9.37664 39.4207C9.37664 39.8625 9.73482 40.2207 10.1766 40.2207H10.3591C10.8009 40.2207 11.1591 39.8625 11.1591 39.4207C11.1591 38.9789 10.8009 38.6207 10.3591 38.6207H10.1766C9.73482 38.6207 9.37664 38.9789 9.37664 39.4207Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.93604 42.8289C4.93604 43.2707 5.29421 43.6289 5.73604 43.6289H5.91851C6.36034 43.6289 6.71851 43.2707 6.71851 42.8289C6.71851 42.3871 6.36034 42.0289 5.91851 42.0289H5.73604C5.29421 42.0289 4.93604 42.3871 4.93604 42.8289ZM9.37664 42.8289C9.37664 43.2707 9.73482 43.6289 10.1766 43.6289H10.3591C10.8009 43.6289 11.1591 43.2707 11.1591 42.8289C11.1591 42.3871 10.8009 42.0289 10.3591 42.0289H10.1766C9.73482 42.0289 9.37664 42.3871 9.37664 42.8289Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.0015 18.4378C17.0015 18.8796 17.3596 19.2378 17.8015 19.2378H20.8455C21.2873 19.2378 21.6455 18.8796 21.6455 18.4378V15.0875C21.6455 14.6456 21.2873 14.2875 20.8455 14.2875H17.8015C17.3596 14.2875 17.0015 14.6456 17.0015 15.0875V18.4378ZM18.6015 17.6378V15.8875H20.0455V17.6378H18.6015Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.001 10.8641C17.001 11.3059 17.3591 11.6641 17.801 11.6641H20.845C21.2869 11.6641 21.645 11.3059 21.645 10.8641V7.51374C21.645 7.07192 21.2869 6.71374 20.845 6.71374H17.801C17.3591 6.71374 17.001 7.07192 17.001 7.51374V10.8641ZM18.601 10.0641V8.31374H20.045V10.0641H18.601Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.6489 18.4378C23.6489 18.8796 24.0071 19.2378 24.4489 19.2378H27.493C27.9348 19.2378 28.293 18.8796 28.293 18.4378V15.0875C28.293 14.6456 27.9348 14.2875 27.493 14.2875H24.4489C24.0071 14.2875 23.6489 14.6456 23.6489 15.0875V18.4378ZM25.2489 17.6378V15.8875H26.693V17.6378H25.2489Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.6484 10.8641C23.6484 11.3059 24.0066 11.6641 24.4484 11.6641H27.4925C27.9343 11.6641 28.2925 11.3059 28.2925 10.8641V7.51374C28.2925 7.07192 27.9343 6.71374 27.4925 6.71374H24.4484C24.0066 6.71374 23.6484 7.07192 23.6484 7.51374V10.8641ZM25.2484 10.0641V8.31374H26.6925V10.0641H25.2484Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.0015 26.011C17.0015 26.4529 17.3596 26.811 17.8015 26.811H20.8455C21.2873 26.811 21.6455 26.4529 21.6455 26.011V22.6608C21.6455 22.219 21.2873 21.8608 20.8455 21.8608H17.8015C17.3596 21.8608 17.0015 22.219 17.0015 22.6608V26.011ZM18.6015 25.211V23.4608H20.0455V25.211H18.6015Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.6489 26.011C23.6489 26.4529 24.0071 26.811 24.4489 26.811H27.493C27.9348 26.811 28.293 26.4529 28.293 26.011V22.6608C28.293 22.219 27.9348 21.8608 27.493 21.8608H24.4489C24.0071 21.8608 23.6489 22.219 23.6489 22.6608V26.011ZM25.2489 25.211V23.4608H26.693V25.211H25.2489Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.0015 33.5843C17.0015 34.0261 17.3596 34.3843 17.8015 34.3843H20.8455C21.2873 34.3843 21.6455 34.0261 21.6455 33.5843V30.234C21.6455 29.7922 21.2873 29.434 20.8455 29.434H17.8015C17.3596 29.434 17.0015 29.7922 17.0015 30.234V33.5843ZM18.6015 32.7843V31.034H20.0455V32.7843H18.6015Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.6489 33.5843C23.6489 34.0261 24.0071 34.3843 24.4489 34.3843H27.493C27.9348 34.3843 28.293 34.0261 28.293 33.5843V30.234C28.293 29.7922 27.9348 29.434 27.493 29.434H24.4489C24.0071 29.434 23.6489 29.7922 23.6489 30.234V33.5843ZM25.2489 32.7843V31.034H26.693V32.7843H25.2489Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M45.8249 48C46.2667 48 46.6249 47.6418 46.6249 47.2V19.9688C46.6249 19.5269 46.2667 19.1688 45.8249 19.1688H31.3752C30.9334 19.1688 30.5752 19.5269 30.5752 19.9688C30.5752 20.4106 30.9334 20.7688 31.3752 20.7688H45.0249V47.2C45.0249 47.6418 45.3831 48 45.8249 48Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M33.2949 28.0081C33.2949 28.4499 33.6531 28.8081 34.0949 28.8081H36.9249C37.3667 28.8081 37.7249 28.4499 37.7249 28.0081V25.1006C37.7249 24.6588 37.3667 24.3006 36.9249 24.3006H34.0949C33.6531 24.3006 33.2949 24.6588 33.2949 25.1006V28.0081ZM34.8949 27.2081V25.9006H36.1249V27.2081H34.8949Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M39.4751 28.0081C39.4751 28.4499 39.8333 28.8081 40.2751 28.8081H43.105C43.5469 28.8081 43.905 28.4499 43.905 28.0081V25.1006C43.905 24.6588 43.5469 24.3006 43.105 24.3006H40.2751C39.8333 24.3006 39.4751 24.6588 39.4751 25.1006V28.0081ZM41.0751 27.2081V25.9006H42.305V27.2081H41.0751Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M33.2949 34.7176C33.2949 35.1594 33.6531 35.5176 34.0949 35.5176H36.9249C37.3667 35.5176 37.7249 35.1594 37.7249 34.7176V31.8101C37.7249 31.3683 37.3667 31.0101 36.9249 31.0101H34.0949C33.6531 31.0101 33.2949 31.3683 33.2949 31.8101V34.7176ZM34.8949 33.9176V32.6101H36.1249V33.9176H34.8949Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M39.4751 34.7176C39.4751 35.1594 39.8333 35.5176 40.2751 35.5176H43.105C43.5469 35.5176 43.905 35.1594 43.905 34.7176V31.8101C43.905 31.3683 43.5469 31.0101 43.105 31.0101H40.2751C39.8333 31.0101 39.4751 31.3683 39.4751 31.8101V34.7176ZM41.0751 33.9176V32.6101H42.305V33.9176H41.0751Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M33.2949 41.4266C33.2949 41.8684 33.6531 42.2266 34.0949 42.2266H36.9249C37.3667 42.2266 37.7249 41.8684 37.7249 41.4266V38.5191C37.7249 38.0773 37.3667 37.7191 36.9249 37.7191H34.0949C33.6531 37.7191 33.2949 38.0773 33.2949 38.5191V41.4266ZM34.8949 40.6266V39.3191H36.1249V40.6266H34.8949Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M39.4751 41.4266C39.4751 41.8684 39.8333 42.2266 40.2751 42.2266H43.105C43.5469 42.2266 43.905 41.8684 43.905 41.4266V38.5191C43.905 38.0773 43.5469 37.7191 43.105 37.7191H40.2751C39.8333 37.7191 39.4751 38.0773 39.4751 38.5191V41.4266ZM41.0751 40.6266V39.3191H42.305V40.6266H41.0751Z"
				fill="#0072EF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M47.9616 47.1999C47.9616 47.6417 47.6034 47.9999 47.1616 47.9999H0.838086C0.396258 47.9999 0.0380859 47.6417 0.0380859 47.1999C0.0380859 46.7581 0.396259 46.3999 0.838087 46.3999H47.1616C47.6034 46.3999 47.9616 46.7581 47.9616 47.1999Z"
				fill="#0072EF"
			/>
		</SVG>
	);
}
