import { createSlice } from "@reduxjs/toolkit";
import QuestionsData from "data/questions";

const initialState = [
	{ id: "1", value: 0, level: 0, updated: false },
	{ id: "2", value: 0, level: 0, updated: false },
	{ id: "3", value: 0, level: 0, updated: false },
	{ id: "4", value: 0, level: 0, updated: false },
	{ id: "5", value: 0, level: 0, updated: false },
	{ id: "6", value: 0, level: 0, updated: false },
];

const getThreeLevels = (value, levels) => {
	switch (true) {
		case value <= 33.33:
			return levels[0];
		case value >= 33.34 && value <= 66.66:
			return levels[1];
		default:
			return levels[2];
	}
};

const getFourLevels = (value, levels) => {
	switch (true) {
		case value <= 24:
			return levels[0];
		case value >= 25 && value <= 49:
			return levels[1];
		case value >= 50 && value <= 74:
			return levels[2];
		default:
			return levels[3];
	}
};

const getLevel = (value, levels) => {
	const levelsLength = levels.length;

	if (levelsLength === 3) return getThreeLevels(value, levels);
	return getFourLevels(value, levels);
};

export const answersSlice = createSlice({
	name: "answers",
	initialState,
	reducers: {
		updateAnswers: (state, action) => {
			const { id, value, usage } = action.payload;
			const findQuestionLevels = QuestionsData.filter((item) => item.id === id)[0]
				.levels;
			const findLevels = findQuestionLevels.filter(
				(item) => item.usage === usage
			)[0].values;
			const level = getLevel(value, findLevels);
			const index = state.findIndex((item) => item.id === id);
			state[index].value = value;
			state[index].level = id === "6" ? 0 : level;
			state[index].updated = true;
		},
		resetAnswers: () => initialState,
	},
});

export const { updateAnswers, resetAnswers } = answersSlice.actions;
