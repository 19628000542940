import { useNavigate } from "react-router-dom";

import Button from "components/elements/button";
import Heading from "components/elements/heading";
import Icon, { IconsType } from "components/elements/icon";
import IconCircle from "components/elements/icon-circle";

import { Wrapper } from "./index.styles";

export type Props = {
	icon?: IconsType | any;
	title?: string;
	back?: string;
	content: React.ReactElement | string;
};

export default function CardPrimary({ icon, title, back, content }: Props) {
	const navigate = useNavigate();
	return (
		<Wrapper className="card-primary" indent={!!icon}>
			{icon && <IconCircle icon={icon} size="s" />}
			{title && <Heading>{title}</Heading>}
			{content}

			{back && (
				<Button variant="underline" size="m" onClick={() => navigate(-1)}>
					<Icon size="s" as="arrow-left" color="primary" />
					<strong>{back}</strong>
				</Button>
			)}
		</Wrapper>
	);
}
