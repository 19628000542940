export type Props = {
	colorA: string | undefined;
	colorB: string;
};
export const getColor = ({ colorA, colorB }: Props) => {
	return colorA ? `var(--${colorA})` : colorB;
};

export function addZeroes(num: number) {
	const result = num?.toFixed(
		Math.max((`${num}`.split(".")[1] || "").length, 2)
	);
	return result || undefined;
}
