import styled, { css } from "styled-components";
import { Page as _Page } from "index.styles";
import { Devices } from "constants";

import Img1 from "./img-1.svg";
import Img1M from "./img-1-m.svg";
import Img1T from "./img-1-t.svg";

const { tablet, mobile } = Devices;

const getSize = (size) => {
	switch (size) {
		case "m":
			return css`
				max-width: ${1020 + 35 * 2}px;
				padding: 0 35px;
				@media screen and (max-width: ${tablet}) {
					max-width: 100%;
				}
				@media screen and (max-width: ${mobile}) {
					padding: 0 16px;
				}
			`;
		case "f":
			return css`
				max-width: 100%;
				padding: 0;
			`;
		default:
			return css`
				max-width: ${648 + 35 * 2}px;
				padding: 0 35px;
				@media screen and (max-width: ${tablet}) {
					max-width: 100%;
				}
				@media screen and (max-width: ${mobile}) {
					padding: 0 16px;
				}
			`;
	}
};

export const Wrapper = styled.div`
	margin: 0 auto;
	display: flex;
	flex-direction: column;

	> .text {
		text-align: center;
		margin: 15px 0 0;
		padding: 0 10px;
	}
`;

export const Page = styled(_Page)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	position: relative;
	z-index: 1;
	background-image: url(${Img1});
	background-repeat: no-repeat;
	background-position: center 70%;
	background-size: cover;
	padding: 50px 0 35px;
	@media screen and (max-width: ${tablet}) {
		background-image: url(${Img1T});
	}
	@media screen and (max-width: ${mobile}) {
		background-image: url(${Img1M});
		background-position: center 0;
		padding: 16px 0;
	}
`;

export const Container = styled.div`
	${({ size }) => getSize(size)};
	width: 100%;
	margin: 0 auto;
	color: var(--primary);
`;

export const MiddleBar = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 13px; // how to play mobile fixes
	justify-content: center;
	align-items: center;
`;
