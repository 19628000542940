import { Wrapper } from "./index.styles";
import { getIcon } from "./helper";

export type IconsType =
	| "arrow-left"
	| "arrow-right"
	| "bulb"
	| "chain"
	| "chat"
	| "dollar-tag"
	| "envelope"
	| "facebook"
	| "globe-meter"
	| "he-mic"
	| "he-tash"
	| "he-shave"
	| "he-cap"
	| "he-beard"
	| "home-l"
	| "home-m"
	| "home-s"
	| "instagram"
	| "linkedin"
	| "security"
	| "she-curly"
	| "she-pony"
	| "skyscraper"
	| "sme"
	| "solar-sun"
	| "warning"
	| undefined;

export interface Props {
	as?: IconsType;

	size?: "xs" | "s" | "m" | "l" | "default";
	color?: string;
}

export default function Icon({ as, size = "default", color }: Props) {
	return (
		<Wrapper className={`icon ${size}`} size={size}>
			{getIcon(as, color)}
		</Wrapper>
	);
}
