import SVG, { Props } from "utils/svg";

export default function Warning({ color, width, height }: Props) {
	return (
		<SVG
			viewBox="0 0 20 20"
			aria-labelledby="Warning"
			color={color}
			height={height}
			width={width}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM11 9V15H9V9H11ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM11 5V7H9V5H11Z"
				fill={color}
			/>
		</SVG>
	);
}
