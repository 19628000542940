import SVG, { Props } from "utils/svg";

export default function Chain({ color, width, height }: Props) {
	return (
		<SVG
			viewBox="0 0 28 28"
			aria-labelledby="Chain"
			color={color}
			height={height}
			width={width}
		>
			<path
				d="M11.9412 8.98633C10.0656 8.98633 8.26676 9.73122 6.94021 11.0572L2.82136 15.1747C1.49478 16.5013 0.749512 18.3005 0.749512 20.1766C0.749512 22.0527 1.49478 23.8519 2.82136 25.1785C4.14794 26.5051 5.94717 27.2503 7.82324 27.2503C9.69931 27.2503 11.4985 26.5051 12.8251 25.1785L14.8838 23.1198C15.3719 22.6317 15.3719 21.8402 14.8838 21.3521C14.3956 20.8639 13.6042 20.8639 13.116 21.3521L11.0574 23.4107C10.1996 24.2685 9.03627 24.7503 7.82324 24.7503C6.61021 24.7503 5.44687 24.2685 4.58913 23.4107C3.73139 22.553 3.24951 21.3896 3.24951 20.1766C3.24951 18.9637 3.73133 17.8004 4.58898 16.9426L8.70765 12.8253C9.56538 11.968 10.7285 11.4863 11.9412 11.4863C13.154 11.4863 14.3171 11.968 15.1749 12.8254C15.6631 13.3134 16.4546 13.3132 16.9426 12.825C17.4307 12.3367 17.4305 11.5453 16.9423 11.0572C15.6157 9.73122 13.8169 8.98633 11.9412 8.98633Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.1749 2.82185C16.5014 1.49527 18.3007 0.75 20.1767 0.75C22.0528 0.75 23.852 1.49527 25.1786 2.82185C26.5052 4.14843 27.2505 5.94766 27.2505 7.82373C27.2505 9.6998 26.5052 11.499 25.1786 12.8256L25.1785 12.8258L21.0598 16.9431L21.0598 16.9431C19.7332 18.2691 17.9344 19.014 16.0587 19.014C14.1831 19.014 12.3843 18.2691 11.0577 16.9431C10.5694 16.4551 10.5693 15.6636 11.0573 15.1754C11.5454 14.6871 12.3368 14.6869 12.8251 15.175C13.6828 16.0324 14.846 16.514 16.0587 16.514C17.2715 16.514 18.4346 16.0324 19.2924 15.175L23.4109 11.0578C23.4109 11.0578 23.411 11.0578 23.411 11.0577C24.2687 10.2 24.7505 9.03669 24.7505 7.82373C24.7505 6.6107 24.2686 5.44736 23.4109 4.58961C22.5531 3.73187 21.3898 3.25 20.1767 3.25C18.9637 3.25 17.8004 3.73187 16.9426 4.58961L14.884 6.64828C14.3958 7.13644 13.6043 7.13644 13.1162 6.64828C12.628 6.16013 12.628 5.36867 13.1162 4.88051L15.1749 2.82185L16.0587 3.70573L15.1749 2.82185Z"
				fill={color}
			/>
		</SVG>
	);
}
