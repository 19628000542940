import { useMediaQuery } from "react-responsive";

import Base from "components/templates/base";
import CardSecondary from "components/sections/card-secondary";
import Carousel from "components/sections/carousel";
import Data from "data/usage.json";

import { Devices } from "../../constants";
import { Wrapper } from "./index.styles";

const { mobile } = Devices;

export default function TellUs() {
	const isMobile = useMediaQuery({ query: `(max-width: ${mobile})` });

	return (
		<Base
			title="Tell us about yourself"
			tagline={
				<>
					Select the household description that{" "}
					<strong>best matches your energy use…</strong>
				</>
			}
			size="f"
		>
			{!isMobile && (
				<Wrapper>
					{Data.map((item) => {
						return (
							<CardSecondary
								key={item.id}
								icon={item.icon}
								title={item.title}
								text={item.text}
								usage={item.usage}
							/>
						);
					})}
				</Wrapper>
			)}
			{isMobile && (
				<Carousel>
					{Data.map((item) => {
						return (
							<CardSecondary
								key={item.id}
								icon={item.icon}
								title={item.title}
								text={item.text}
								usage={item.usage}
							/>
						);
					})}
				</Carousel>
			)}
		</Base>
	);
}
